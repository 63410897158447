import CryptoJS from 'crypto-js'

const config = {
	mode: CryptoJS.mode.CFB,
	padding: CryptoJS.pad.AnsiX923,
}

export const aes = {
		encrypt: function (string, key) {
			return CryptoJS.AES.encrypt(string, key, config).toString()
		},
		decrypt: function (string, key) {
			return CryptoJS.AES.decrypt(string, key, config).toString(
				CryptoJS.enc.Utf8
			)
		},
	},
	md5 = {
		encrypt: function (string) {
			return CryptoJS.MD5(string).toString(CryptoJS.enc.Hex)
		},
		decrypt: function (string) {
			return CryptoJS.MD5(string).toString(CryptoJS.enc.Utf8)
		},
	}
