import { couch_uuid, index } from './fields'

export const schema = {
	title: 'patient alert schema',
	description: 'describes a single patient alert',
	version: 0,
	keyCompression: false,
	primaryKey: 'couch_uuid',
	type: 'object',
	properties: {
		couch_uuid,
		examination_uuid: { // not used
			...couch_uuid,
		},
		alert_attribute_uuid: {
			...couch_uuid,
			ref: 'alert_attributes',
		},
		patient_uuid: {
			...couch_uuid,
			ref: 'patients',
		},
		alert_attribute_code_uuid: {
			...couch_uuid,
			ref: 'alert_attribute_codes',
		},

		creation_date: {
			...index,
			type: 'string',
		},
		current_value: {
			type: 'string',
		},
		arrow: {
			type: 'string',
		},
		color: {
			type: 'string',
		},
		is_active: {
			type: 'boolean',
		},
	},
	required: [
		'couch_uuid',
		'examination_uuid',
		'alert_attribute_uuid',
		'patient_uuid',
	],
	indexes: [['patient_uuid', 'creation_date']],
}

export default schema
