import { couch_uuid, id } from './fields'

export const schema = {
	title: 'drug application internal schema',
	description: 'describes a single stay note',
	version: 0,
	keyCompression: false,
	primaryKey: 'couch_uuid',
	type: 'object',
	properties: {
		couch_uuid,
		
		created_time: {
			type: 'string',
			final: true,
		},

		// persistent id
		drug_application_id_ref: {
			...id,
		},
		person_id_ref: {
			...id,
		},
		stay_id_ref: {
			...id,
		},

		// other expressionfields
		person_first_name: {
			type: 'string',
		},
		person_last_name: {
			type: 'string',
		},
		person_role: {
			type: 'string',
		},
	},
	required: ['couch_uuid', 'drug_application_id_ref', 'person_id_ref', 'stay_id_ref', 'created_time'],
}

export default schema
