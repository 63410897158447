import { couch_uuid, id } from './fields'

import { getAsBool } from '@/functions/env'
const VUE_APP_MODULE_NOTE_SEND_TO_CN_ENABLED = getAsBool('VUE_APP_MODULE_NOTE_SEND_TO_CN_ENABLED')

const getProperties = () => {
	let properties = {
		couch_uuid,
		date_created: {
			type: 'string',
			final: true,
		},
		
		type: {
			type: 'string',
		},
		text: {
			type: 'string',
		},

		// persistent id
		stay_id_ref: {
			...id,
		},
		person_id_ref: {
			...id,
		},

		// other expressionfields
		person_first_name: {
			type: 'string',
		},
		person_last_name: {
			type: 'string',
		},
		person_role: {
			type: 'string',
		},
		
		treatment_form_remote_id: {
			...id
		}
	}

	if (!VUE_APP_MODULE_NOTE_SEND_TO_CN_ENABLED) {
		delete properties.treatment_form_remote_id
	}

	return properties
}
	
export const schema = {
	title: 'stay note schema',
	description: 'describes a single stay note',
	version: 0,
	keyCompression: false,
	primaryKey: 'couch_uuid',
	type: 'object',
	properties: getProperties(),
	required: ['couch_uuid'],
}

export default schema
