import { couch_uuid, id, index } from './fields'

import { getAsString } from '@/functions/env'

const VUE_APP_DRUG_APPLICATIONS_DATE_INDEX_FIELD_NAME = getAsString('VUE_APP_DRUG_APPLICATIONS_DATE_INDEX_FIELD_NAME')

export const schema = {
	title: 'drug application schema',
	description: 'describes a single drug application',
	version: 0,
	keyCompression: false,
	primaryKey: 'couch_uuid',
	type: 'object',
	properties: {
		couch_uuid,
		id: {
			...id,
		},

		// persistent id
		stay_id_ref: {
			...id,
		},

		application_date: {
			...index,
			type: 'string',
		},
		application_way: {
			type: 'string',
		},
		code: {
			type: 'string',
		},
		comments: {
			type: 'string',
		},
		ean: {
			type: 'string',
		},
		form: {
			type: 'string',
		},
		frequency_name: {
			type: 'string',
		},
		name: {
			type: 'string',
		},
		order_date: {
			...index,
			type: 'string',
		},
		order_modification_date: {
			type: 'string',
		},
		order_start_date: {
			...index,
			type: 'string',
		},
		order_stop_date: {
			type: 'string',
		},
		service_id: {
			...id,
		},
		status: {
			type: 'string',
		},
		unit_name: {
			type: 'string',
		},
		units_count: {
			type: 'string',
		},
	},
	required: ['couch_uuid', 'stay_id_ref'],
	indexes: [
		// ['stay_id_ref', 'order_date'],
		// ['stay_id_ref', 'order_start_date'],
		['stay_id_ref', VUE_APP_DRUG_APPLICATIONS_DATE_INDEX_FIELD_NAME],
	],
}

export default schema
