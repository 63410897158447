<template>
	<div v-if="show" class="refresh-page">
		<p>
			Używasz aplikacji w wersji {{ currentAppVersion || '-' }}, istnieje nowsza wersja. {{ serverAppVersion || '-' }}. Proszę przeładować aplikację używając przycisku "Odśwież".
		</p>
		<v-btn
			color="white"
			depressed
			:disabled="reloadAppLoading"
			@click.stop="reloadApp"
		>
			Odśwież
		</v-btn>
	</div>
</template>

<script>
	import {
		locationReload
	} from '@/functions/appVersion'
	
	export default {
		data() {
			return {
				reloadAppLoading: false
			}
		},
		computed: {
			currentAppVersion() {
				return this.$store.getters.appVersion
			},
			showSnackbar() {
				return this.$store.getters['UpdateApp/showSnackbar']
			},
			serverAppVersion() {
				return this.$store.getters['UpdateApp/getServerAppVersion']
			},
			show() {
				if (!!this.serverAppVersion && this.showSnackbar) return true
				else return false
			},
		},
		methods: {
			reloadApp() {
				if(this.reloadAppLoading)
					return
			
				this.reloadAppLoading = true
				
				try {
					if(caches)
						caches.keys().then(function (names) {
							for (let name of names) {
								caches.delete(name)
							}
						})
	
					navigator.serviceWorker.getRegistrations().then((registrations) => {
						registrations.forEach((registration) => {
							registration.unregister()
						})
					})
					
					const self = this
	
					navigator.serviceWorker.getRegistration().then(function (reg) {
						if (reg)
							reg.unregister().then(function () {
								self.reloadAppPost()
							})
						else self.reloadAppPost()
					})
				} catch (e) {
					this.reloadAppLoading = false
					throw e
				}
			},
			reloadAppPost() {
				this.reloadAppLoading = false
				locationReload()
			}
		},
	}
</script>

<style lang="scss">
@import '@/assets/scss/colors.scss';

.refresh-page {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	height: fit-content;
	background-color: $channel-marker-green;
	z-index: 194;
	border-radius: 12px 12px 0 0;
	padding: 24px 48px;

	display: flex;
	justify-content: center;
	align-items: center;
	column-gap: 24px;

	p {
		margin: 0 !important;
		color: white;
		font-weight: 500;
	}

	.v-btn:nth-of-type(1) {
		min-height: 40px !important;
		max-height: 40px !important;
		height: 40px !important;
		color: $channel-marker-green !important;
		font-weight: 600 !important;
	}
	.v-btn:nth-of-type(2) {
		min-width: 40px !important;
		max-width: 40px !important;
		width: 40px !important;
		min-height: 40px !important;
		max-height: 40px !important;
		height: 40px !important;
		padding: 0 !important;
	}
}
</style>
