import { couch_uuid } from './fields'

export const schema = {
	title: 'org unit rooms',
	description: 'describes a org unit room model',
	version: 0,
	keyCompression: false,
	primaryKey: 'couch_uuid',
	type: 'object',
	properties: {
		couch_uuid,
		org_unit_uuid: {
			...couch_uuid,
			ref: 'org_units',
		},
		code: {
			// max długość to 3 znaki, ale prewencyjnie niech max bedzie 4
			type: 'string',
			minLength: 1,
			maxLength: 4,
		},
		name: {
			type: 'string',
		},
	},
	required: ['couch_uuid', 'org_unit_uuid'],
	// indexes: [],
}

export default schema
