import { couch_uuid } from './fields'

export const schema = {
	title: 'patient attribute alert code schema',
	description: 'describes a single patient attribute alert code',
	version: 0,
	keyCompression: false,
	primaryKey: 'couch_uuid',
	type: 'object',
	properties: {
		couch_uuid,
		alert_attribute_uuid: {
			...couch_uuid,
			ref: 'alert_attributes',
		},
		service_uuid: {
			...couch_uuid,
		},

		param_code: {
			type: 'string',
		},
		param_name: {
			type: 'string',
		},
	},
	required: ['couch_uuid', 'alert_attribute_uuid'],
}

export default schema
