import store from '@/store'
import router from '@/router'

import { getAsNumber, getAsBool } from '@/functions/env'

import {
	getAuthSS,
	getSpeechRecognizer,
	getAudioConfigForRealTimeMicrophoneRecognition,
	getSpeechConfig,
	getSpeechSDK,
	defaultCanceled,
	defaultSpeechEndDetected,
	defaultRecognized,
	isMatch,
} from '@/functions/azure.js'

const findLastIndex = function(array, callback) {
	if(array.findLastIndex)
		return array.findLastIndex(callback)

    for (let i = array.length - 1; i >= 0; i--) {
        if (callback(array[i], i, array)) {
            return i;
        }
    }
    return -1; // Jeśli element nie został znaleziony
}

import { getBestMatchIndex } from '@/functions/compareStrings'

const isEnabled = getAsBool('VUE_APP_MODULE_AZURE_VC_ENABLED'),
	_create_speech_recognizer = async function ({ token, region } = {}) {
		console.debug(`[STTService::_create_speech_recognizer] started`)
		
		try {
			store.dispatch('CurrentSTT/reset')
			
			if(!token || !region){
				if(!token)
					console.warn('[STTService::_create_speech_recognizer] token is empty')
				if(!region)
					console.warn(`[STTService::_create_speech_recognizer] region is empty`)
					
				console.debug(`[STTService::_create_speech_recognizer] finished`)
				return
			}

			const speechConfig = await getSpeechConfig(token, region),
				audioConfig = getAudioConfigForRealTimeMicrophoneRecognition(),
				speechRecognizer = getSpeechRecognizer(
					speechConfig,
					audioConfig
				)

			console.debug(`[STTService::_create_speech_recognizer] finished`)
			
			return speechRecognizer
		} catch (e) {
			console.error(e)
		}
	},
	autoDisableTime = getAsNumber('VUE_APP_VC_DISABLE_TIME')

class STTService {
	static #singleton = null
	static #initailizedPromise = null
	static async singleton() {
		if (this.#initailizedPromise == null) {
			this.#singleton = new STTService()

			this.#initailizedPromise = this.#singleton.initialize()
		}
		return await this.#initailizedPromise
	}

	constructor() {}
	
	async getAuth() {
		console.debug(`[STTService::getAuth]`)
		try {
			const { token, region } = await getAuthSS() || {}
		
			return {
				token, region
			}
		} catch (e) {
			console.warn(e)
			
			return null
		}
	}
	
	
	async updateAuth() {
		console.debug(`[STTService::updateAuth]`)
		this.auth = await this.getAuth()
	}
	 
    resetAuth() {
        console.debug(`[STTService::resetAuth]`)
		this.auth = null
	}
	
	async postCreate() {
		if (this.sr) {
			this.sr.canceled = async (s, e) => {
				defaultCanceled(s, e)
				
				this.resetAuth()
				await this.createSR()
				await this.startCRA()
			}

			this.sr.speechEndDetected = () => {
				defaultSpeechEndDetected()

				this.setTimeoutSrAutoDisable()
			}
		}
	}
	
	async createSR() {
		console.debug(`[STTService::createSR] started`)
		
		if(!this.auth)
			await this.updateAuth()
		
		this.sr = await _create_speech_recognizer(this.auth || {})
		
		if(this.sr){
			await this.postCreate()
			this.applyCommands()
		}
		
		console.debug(`[STTService::createSR] finished`)
		
	}

	async initialize() {
		if(!isEnabled){
			console.debug(`[STTService::initialize] feature is disabled`)
			return
		}
			
		console.debug(`[STTService::initialize] started`)
		
		await this.createSR()
		
		this.sdk = getSpeechSDK()
		this.actions = []

		store.dispatch('CurrentSTT/reset')

		console.debug(`[STTService::initialize] finished`)
		
		return this
	}

	async checkIfSRInstanceExistIfNotCreateOne() {
		console.debug(
			`[checkIfSRInstanceExistIfNotCreateOne] checking if db exists...`
		)

		try {
			this.checkSr()
		} catch (error) {
			console.debug(
				`[checkIfSRInstanceExistIfNotCreateOne] creating new db instance...`
			)
			
			await this.createSR()
			this.applyCommands()
		}
	}

	updateLastRecognizedTime() {
		if (autoDisableTime) {
			store.dispatch('CurrentSTT/updateLastRecognizedTime')
			console.debug(`[updateLastRecognizedTime] updated`)

			this.setTimeoutSrAutoDisable()
		}
	}

	setTimeoutSrAutoDisable() {
		if (autoDisableTime) {
			console.debug(`[setTimeoutSrAutoDisable] ${autoDisableTime} sec`)
			const self = this
			setTimeout(() => {
				self.srAutoDisable()
			}, autoDisableTime * 1000)
		}
	}

	checkSr() {
		console.debug(`[checkSr] started`)
		
		if(!isEnabled)
			return
		
		if (!this.sr) throw new Error("sr instance don't exist")
		console.debug(`[checkSr] finished`)
	}

	async startCRA() {
		console.debug(`[startCRA]`)
		await store.dispatch('CurrentUser/updateNetworkStatus')
		
		if(store.state.CurrentUser?.network_status === 'online'){
			await this.checkIfSRInstanceExistIfNotCreateOne()
	
			try {
				this.sr?.startContinuousRecognitionAsync()
	
				store.dispatch('CurrentSTT/start')
				this.watchConnection(true)
			} catch (e) {
				console.debug('[startCRA] failed')
				store.dispatch('CurrentSTT/stop')
	
				throw e
			}
		}
		
	}

	async stopCRA() {
		console.debug(`[stopCRA]`)
		
		try {
			this.checkSr()
			this.sr?.stopContinuousRecognitionAsync()
		} catch (e) {
			if(e.message !== `sr instance don't exist`)
				throw e
		} finally {
			store.dispatch('CurrentSTT/stop')
			await this.disableCRA()
		}
	}
	
	async disableCRA() {
		console.debug(`[disableCRA]`)
		store.dispatch('CurrentSTT/reset')
		await this?.dispose()
		this.sr = null
	}
	
	async dispose() {
		console.debug(`[dispose]`)
		await this?.sr?.dispose(true)
	}
	
	async watchConnection (isInit=false) {
		if(isInit)
			console.debug(`[watchConnection] start`)
		const self = this
		return new Promise((resolve) => {
			setTimeout(async () => {
				await store.dispatch('CurrentUser/updateNetworkStatus')
					
				const { isRecognizing } = store.state.CurrentSTT
					
				if(isRecognizing)
					resolve(await self.watchConnection(false))
				else {
					console.debug(`[watchConnection] finished`)
					resolve()
				}
			}, 5 * 1000);
		});
	}

	async srAutoDisable() {
		const { isRecognizing, lastRecognizedTime } = store.state.CurrentSTT
		const t = isRecognizing && lastRecognizedTime

		if (t && (Date.now() - t) / 1000 > autoDisableTime) {
			console.debug(
				`[srAutoDisable] automatyczna deaktywacja STT po ${autoDisableTime} sec`
			)
			await this.stopCRA()
		}
	}

	getAvailableActions() {
		// console.debug(`[getAvailableActions]`)
		const routeName = router?.app?.$route?.name
		const actions =
			this.actions?.filter(
				(a) =>
					a.routeName === routeName ||
					a?.routeNames?.includes(routeName)
			) || []

		return actions
	}

	printAvailableActions() {
		const a = this.getAvailableActions()
		console.debug(
			`[dostępne komendy]: ${
				a
					?.map(
						(action) =>
							`\n"${action?.phrase || '-'}" -> ${
								action?.description || '-'
							}`
					)
					?.join(', ') || '-'
			}`
		)
	}

	addCommands(actions) {
		const routeName = router?.app?.$route?.name

		actions = actions.map((a) => ({
			...a,
			routeName: routeName,
		}))

		actions.forEach((action) => {
			if (!action?.description)
				console.warn(`No description for action: ${action.phrase}`)
		})

		console.debug(`[addCommands]`, actions)

		this.actions = [...this.actions, ...actions]

		this.actions = this.actions.filter((a, aIndex, arr) => {
			if (findLastIndex(arr, (a1) => a1.phrase === a.phrase) > aIndex)
				return null
			return true
		})

		if (this.sr) this.applyCommands()
		else console.debug(`[addCommands] sr is not initialized`)
	}

	getMatchedAction(text = null, actions = this.getAvailableActions()) {
		let actionIndex = getBestMatchIndex(
			text,
			actions.map((o) => o.phrase)
		)

		if (actionIndex >= 0 && actionIndex !== null) {
			const action = actions[actionIndex]

			console.debug(`[getMatchedAction]`, action)

			return action
		}

		return null
	}

	applyCommands() {
		console.debug(`[applyCommands]`)
		
		if(this.sr?.recognized){
			this.sr.recognized = (s, e) => {
				defaultRecognized(s, e)
	
				if (isMatch(e)) {
					const text = e.result.text
					const matchedAction = this.getMatchedAction(text)
	
					if (matchedAction) {
						store.dispatch('CurrentSTT/updateAction', matchedAction)
						matchedAction?.action()
					}
	
					this.updateLastRecognizedTime()
				}
			}
			this.printAvailableActions()
		}
	}
}

export default STTService
