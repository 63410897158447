import moment from 'moment'

import store from '@/store'

import { statusesCancelled, statusesPending, /* statusesResults */  } from '@/functions/styleByStatus'
import { getAsDateTime, getAsString, getAsArray } from '@/functions/env'

const VUE_APP_DRUG_APPLICATIONS_SORTED_BY = getAsString(
	'VUE_APP_DRUG_APPLICATIONS_SORTED_BY'
)
const VUE_APP_STAY_TREATMENT_SECTION_TITLE = getAsString(
		'VUE_APP_STAY_TREATMENT_SECTION_TITLE_FILTER'
	) || getAsString(
		'VUE_APP_STAY_TREATMENT_SECTION_TITLE'
	),
	VUE_APP_DRUG_APPLICATIONS_DATE_INDEX_FIELD_NAME = getAsString(
		'VUE_APP_DRUG_APPLICATIONS_DATE_INDEX_FIELD_NAME'
	),
	VUE_APP_AZURE_AI_TREATMENT_FILTER_REGEX = getAsString(
		'VUE_APP_AZURE_AI_TREATMENT_FILTER_REGEX'
	),
	// eslint-disable-next-line no-unused-vars
	VUE_APP_DRUG_APPLICATIONS_TRAY_FILTER_DATE_PROPERTY = getAsArray(
		'VUE_APP_DRUG_APPLICATIONS_TRAY_FILTER_DATE_PROPERTY'
	),
	VUE_APP_MODULE_PATOGENS_FILTER_REGEX = getAsString(
		'VUE_APP_MODULE_PATOGENS_FILTER_REGEX'
	)
	

const isValidDataProperty = (dataProperty) => {
		const dataPropertyOptions = [
			'application_date',
			'order_date',
			'examination_date',
			'show_period_examinations',
			'show_period_params',
			'show_period_drugs',
			'date_created',
			'show_period_short_params',
			'creation_date',

			// events
			'datetimeCreated',
		]

		return dataPropertyOptions.includes(dataProperty)
	},
	isValidUnit = (unit) => {
		const unitOptions = ['months', 'days', 'hours', 'minutes', 'seconds']

		return unitOptions.includes(unit)
	}

export const limitObjects = (
		x,
		dataProperty,
		peroid,
		unit = 'days',
		disableDevDatetime = false
	) => {
		if (!isValidDataProperty(dataProperty)) {
			console.error(
				`[limitObjects]: No support for selected dataProperty="${dataProperty}" option`
			)
		}

		if (!isValidUnit(unit)) {
			console.error(
				`[limitObjects]: No support for selected unit="${unit}" option`
			)
		}

		const devDatetime =
			store?.state?.CurrentStays?.dashboard_config?.dev_datetime ?? null,
		dateTime = devDatetime && !disableDevDatetime
			? moment(devDatetime)
			: moment()
			
		peroid = store?.state?.CurrentStays?.dashboard_config?.[
			peroid
		] || peroid

		return x && x.length > 0
			? x.filter(
					(e) => dateTime.diff(moment(e[dataProperty]), unit) <= peroid
			  )
			: []
	},
	// getDateFromPast = function (
	// 	peroid = 0,
	// 	unit = 'days',
	// 	disable_dev_datetime = false
	// ) {
	// 	let dev_datetime =
	// 			store?.state?.CurrentStays?.dashboard_config?.dev_datetime ??
	// 			null,
	// 		m =
	// 			dev_datetime && !disable_dev_datetime
	// 				? moment(dev_datetime)
	// 				: moment()

	// 	return m
	// 		.subtract(
	// 			store?.state?.CurrentStays?.dashboard_config?.[peroid] ??
	// 				peroid,
	// 			unit
	// 		)
	// 		.format('YYYY-MM-DD HH:mm:ss.000000+01:00') // 2023-01-16 05:41:00.000000+01:00
	// 	//.toDate()
	// },
	getMomentNow = function (disable_dev_datetime = false) {
		const dev_datetime =
				store?.state?.CurrentStays?.dashboard_config?.dev_datetime ??
				null,
			m =
				dev_datetime && !disable_dev_datetime
					? moment(dev_datetime)
					: moment()

		return m
	},
	getMomentFromInput = function (date = null) {
		const m = date ? moment(date) : moment()

		return m
	},
	retFormat = 'YYYY-MM-DD HH:mm:ss.000000+01:00',
	getDateNow = function (disable_dev_datetime = false) {
		const m = getMomentNow(disable_dev_datetime)

		return m.format(retFormat)
	},
	isSameDay = function (date = null) {
		const m = getMomentNow()

		return m.isSame(getMomentFromInput(date), 'day')
	},
	getDateFromPastV2 = function (
		peroid = 0,
		unit = 'days',
		disable_dev_datetime = false,
		code = null
	) {
		const m = getMomentNow(disable_dev_datetime)
		let r = null

		if (code) {
			if (code === 'ai')
				r = m
					// .subtract(24, 'hours')
					.subtract(24, 'hours')
					.set({ hour: 15, minute: 0, second: 0, millisecond: 0 })
		}

		if (r === null)
			r = m.subtract(
				store?.state?.CurrentStays?.dashboard_config?.[peroid] ??
					peroid,
				unit
			)

		return r.format(retFormat)
	},
	getDate = function (config = {}, disable_dev_datetime = false) {
		const { time, peroid, unit, inFuture, inPast } = config || {}

		const m = getMomentNow(disable_dev_datetime)
		let r = null

		if (peroid && unit) {
			if (inFuture) r = m.add(peroid, unit)
			else if (inPast) r = m.subtract(peroid, unit)
		}

		if (r === null) r = m

		if (time)
			r = r.set(
				time || { hour: 15, minute: 0, second: 0, millisecond: 0 }
			)

		return r.format(retFormat)
	},
	getSelectorV2 = function (selectorV2 = {}) {
		function processElements(elements) {
			return elements.map(element => {
				if (element.key === '$or' || element.key === '$and') {
					// Rekurencyjne przetwarzanie dla operatorów 'or' i 'and'
					const mongoOperator = `${element.key}`;
					return { [mongoOperator]: processElements(element.elements) };
				} else if (element.type === 'date') {
					// Przetwarzanie dla typu 'date'
					const beginDate = getDate({ ...element.begin, period: element.begin.period, inFuture: false });
					const endDate = getDate({ ...element.end, period: element.end.period, inPast: false });
					return { [element.field]: { $gte: beginDate, $lte: endDate } };
				} else if (element.type === 'standard') {
					// Przetwarzanie dla typu 'standard', używając 'content' zamiast 'value'
					return element.content;
				}
			}).filter(result => result !== undefined); // Filtrujemy, aby usunąć undefined
		}
	  
		if (selectorV2.key && (selectorV2.key === '$or' || selectorV2.key === '$and')) {
			const mongoOperator = `${selectorV2.key}`;
			return { [mongoOperator]: processElements(selectorV2.elements) };
		} else {
			// Domyślnie używamy $or, jeśli klucz nie jest określony
			return { $or: processElements(selectorV2.elements) };
		}
    },
	getFilterForCollection = function (collectionName = null, range = 'all') {
		if (!collectionName)
			throw new Error(`[getFilterForCollection] no collectionName`)

		//TODO add collection services
		const paramsSelector = {
				result: { $nin: [null] },
				gui_type: 'params',
			},
			paramsOnlyNEWS2Selector = {
				...paramsSelector,
				// result: /NEWS/,
				result_form_contain_news: true,
			},
			noParamsSelector = {
				$not: { gui_type: 'params' },
			},
			config = [
				{
					collectionName: 'examinations',
					range: 'all',
					filterDate: {
						dataProperty: ['examination_date', 'order_date'],
						peroid: 'show_period_examinations',
						unit: 'days',
					},
					selectorRaw: {
						status_name: { $nin: statusesCancelled },
					},
				},
				{
					collectionName: 'examinations',
					range: 'params-o',
					filterDate: {
						dataProperty: 'order_date',
						peroid: 'show_period_params',
						unit: 'days',
					},
					selectorRaw: paramsSelector,
				},
				{
					collectionName: 'examinations',
					range: 'all-no-params',
					filterDate: {
						dataProperty: ['examination_date', 'order_date'],
						peroid: 'show_period_examinations',
						unit: 'days',
					},
					selectorRaw: noParamsSelector,
				},
				{
					collectionName: 'examinations',
					range: 'params-o-news',
					filterDate: {
						dataProperty: 'examination_date',
						peroid: 'show_period_params',
						unit: 'days',
					},
					selectorRaw: paramsOnlyNEWS2Selector,
				},
				{
					collectionName: 'examinations',
					range: 'params-e',
					filterDate: {
						dataProperty: 'examination_date',
						peroid: 'show_period_params',
						unit: 'days',
					},
					selectorRaw: paramsSelector,
				},
				{
					collectionName: 'examinations',
					range: 'lab-o',
					filterDate: {
						dataProperty: ['examination_date', 'order_date'],
						peroid: 'show_period_examinations',
						unit: 'days',
					},
					selectorRaw: {
						gui_type: { $in: ['laboratory'] },
					},
				},
				{
					collectionName: 'drug_applications',
					range: 'all',
					filterDate: {
						dataProperty: [
							'application_date',
							'order_date',
							'order_start_date',
						],
						peroid: 'show_period_drugs',
						unit: 'days',
					},
					selectorRaw: {
						// Note that sorted fields also have to be selected in the selector.
						// order_date: { $gt: true },
						// order_start_date: { $gt: true },
						[VUE_APP_DRUG_APPLICATIONS_SORTED_BY]: { $gt: true },
					},
				},
				{
					collectionName: 'drug_applications',
					range: 'current-plus-1day',
					filterDate2: {
						dataProperty: [
							'application_date',
							'order_date',
							'order_start_date',
						],
						begin: {
							time: {
								hour: 0,
								minute: 0,
								second: 0,
								millisecond: 0,
							},
						},
						end: {
							peroid: 1,
							unit: 'day',
							inFuture: true,
							inPast: false, //TODO
							time: {
								hour: 23,
								minute: 59,
								second: 59,
								millisecond: 59,
							},
						},
					},
					selectorRaw: {
						// Note that sorted fields also have to be selected in the selector.
						// order_date: { $gt: true },
						// order_start_date: { $gt: true },
						[VUE_APP_DRUG_APPLICATIONS_SORTED_BY]: { $gt: true },
					},
				},
				{
					collectionName: 'drug_applications',
					range: 'current-day',
					filterDate2: {
						dataProperty: [
							'application_date',
							'order_date',
							'order_start_date',
						],
						begin: {
							time: {
								hour: 0,
								minute: 0,
								second: 0,
								millisecond: 0,
							},
						},
						end: {
							time: {
								hour: 23,
								minute: 59,
								second: 59,
								millisecond: 59,
							},
						},
					},
					selectorRaw: {
						[VUE_APP_DRUG_APPLICATIONS_SORTED_BY]: { $gt: true },
					},
				},
				// {
				// 	collectionName: 'drug_applications',
				// 	range: 'tray-prev-2h-next-4h',
				// 	filterDate2: {
				// 		dataProperty: VUE_APP_DRUG_APPLICATIONS_TRAY_FILTER_DATE_PROPERTY,
				// 		begin: {
				// 			peroid: 2,
				// 			unit: 'hours', 
				// 			inPast: true,
				// 		},
				// 		end: {
				// 			peroid: 4,
				// 			unit: 'hours', 
				// 			inFuture: true,
				// 		},
				// 	},
				// 	selectorRaw: {
				// 		[VUE_APP_DRUG_APPLICATIONS_SORTED_BY]: { $gt: true },
				// 		status: { $nin: statusesCancelled },
				// 	}
				// },
				{
					collectionName: 'drug_applications',
					range: 'tray-prev-2h-next-4h',
					selectorV2: {
						key: '$or',
						type: 'array',
						elements: [
							{
								key: '$and',
								type: 'array',
								elements: [
									{
										type: 'date',
										field: 'order_date',
										begin: {
											peroid: 2,
											unit: 'hours', 
											inPast: true,
										},
										end: {
											peroid: 4,
											unit: 'hours', 
											inFuture: true,
										},
									},
									{
										type: 'standard',
										content: {status: { $in: statusesPending },}
									},
									
									{
										key: '$or',
										type: 'array',
										elements: [
											{
												type: 'standard',
												content: { application_date: { $exists: false } }
											},
											{
												type: 'date',
												field: 'application_date',
												begin: {
													peroid: 2,
													unit: 'hours', 
													inPast: true,
												},
												end: {
													peroid: 4,
													unit: 'hours', 
													inFuture: true,
												},
											},
										]
									}
								]
							},
							{
								key: '$and',
								type: 'array',
								elements: [
									{
										type: 'date',
										field: 'application_date',
										begin: {
											peroid: 2,
											unit: 'hours', 
											inPast: true,
										},
										end: {
											peroid: 4,
											unit: 'hours', 
											inFuture: true,
										},
									},
									{
										type: 'standard',
										content: {status: { $in: statusesPending },}
									}
								]
							}  
						]
					}
				},
				// {
				// 	collectionName: 'drug_applications',
				// 	range: 'next-6-hours-pending',
				// 	filterDate2: {
				// 		dataProperty: [
				// 			'application_date',
				// 			'order_date',
				// 			'order_start_date',
				// 		],
				// 		begin: null,
				// 		end: {
				// 			peroid: 6,
				// 			unit: 'hours', 
				// 			inFuture: true,
				// 		},
				// 	},
				// 	selectorRaw: {
				// 		[VUE_APP_DRUG_APPLICATIONS_SORTED_BY]: { $gt: true },
				// 		status:  { $in: statusesPending},
				// 	},
				// },
				{
					collectionName: 'treatments',
					range: 'all',
					filterDate: {
						dataProperty: ['creation_date', 'modification_date'],
						peroid: 'show_period_descriptive_data',
						unit: 'days',
					},
				},
				{
					collectionName: 'treatments',
					range: 'only-rozpoznanie-wstepne',
					filterDate: {
						dataProperty: 'creation_date', //['creation_date',/* 'modification_date' */],
						peroid: 'show_period_descriptive_data',
						unit: 'days',
					},
					selectorRaw: {
						// name: { $regex: '^Rozpoznanie wstępne( .*)?$' },
						// name: { $regex: '^Opis postępu choroby i leczenia( .*)?$' },
						name: {
							$regex: `^${VUE_APP_STAY_TREATMENT_SECTION_TITLE}( .*)?$`,
						},
					},
				},
				{
					collectionName: 'org_units',
					range: 'only_active',
					selectorRaw: {
						is_active: true,
						is_assigned: true,
					},
				},
				{
					collectionName: 'stays',
					range: 'only_active',
					selectorRaw: {
						stop_date: null,
						patient_uuid: { $gt: true }, // not compatible with rxdb@10.5.4, for this version replace true with 0
					},
				},
				{
					collectionName: 'zwr_patient_alerts',
					range: 'only_active',
					// filterDate: {
					// 	dataProperty: 'examination_date',
					// 	peroid: 'active_period_zwr_patient_alert',
					// 	unit: 'hours',
					// },
					selectorRaw: {
						is_active: true,
					},
				},
				{
					collectionName: 'org_unit_persons',
					range: 'only_for_logged_user',
					selectorRaw: {
						person_id_ref: store?.state?.CurrentUser?.instance?.id,
					},
				},
				{
					collectionName: 'treatments',
					range: 'all_aidesc',
					filterDate: {
						dataProperty: ['creation_date', 'modification_date'],
						aiRange: true,
					},
					selectorRaw: {
						name: {
							$regex: VUE_APP_AZURE_AI_TREATMENT_FILTER_REGEX,
						},
					},
				},
				{
					collectionName: 'examinations',
					range: 'all-no-params_aidesc',
					filterDate: {
						dataProperty: ['examination_date', 'order_date'],
						aiRange: true,
					},
					selectorRaw: noParamsSelector,
				},
				{
					collectionName: 'examinations',
					range: 'params-e_aidesc',
					filterDate: {
						dataProperty: 'examination_date',
						aiRange: true,
					},
					selectorRaw: paramsSelector,
				},
				{
					collectionName: 'examinations',
					range: 'params-e-lab_aidesc',
					filterDate: {
						dataProperty: ['order_date', 'examination_date'], //TODO only examination_date
						aiRange: true,
					},
					selectorRaw: {
						gui_type: 'laboratory',
					},
				},
				{
					collectionName: 'examinations',
					range: 'params-e-con_aidesc',
					filterDate: {
						dataProperty: 'examination_date',
						aiRange: true,
					},
					selectorRaw: {
						gui_type: 'consultation',
					},
				},
				{
					collectionName: 'examinations',
					range: 'params-e-rad_aidesc',
					filterDate: {
						dataProperty: 'examination_date',
						aiRange: true,
					},
					selectorRaw: {
						gui_type: 'radiology',
					},
				},
				{
					collectionName: 'drug_applications',
					range: 'all_aidesc',
					filterDate: {
						dataProperty: ['application_date', 'order_date'],
						aiRange: true,
						aiRangePlusFuture: true,
					},
					selectorRaw: {
						[VUE_APP_DRUG_APPLICATIONS_DATE_INDEX_FIELD_NAME]: {
							$gt: true,
						}, // Note that sorted fields also have to be selected in the selector.
					},
				},
				{
					collectionName: 'drug_applications',
					range: 'all-old_aidesc',
					filterDate: {
						dataProperty: ['application_date', 'order_date'],
						aiRange: true,
						aiRangeOnlyOld: true,
					},
					selectorRaw: {
						[VUE_APP_DRUG_APPLICATIONS_DATE_INDEX_FIELD_NAME]: {
							$gt: true,
						}, // Note that sorted fields also have to be selected in the selector.
					},
				},
				// 	{
				// 		collectionName: 'drug_applications',
				// 		range: 'all_aidesc',
				// 		filterDate: {
				// 			dataProperty: ['application_date', 'order_date'],
				// 			aiRangePlusFuture: true,
				// 		},
				// 		selectorRaw: {
				// 			order_date: { $gt: true }, // Note that sorted fields also have to be selected in the selector.
				// 		},
				// 	},
				{
					collectionName: 'pathogen_instances',
					range: 'only_active',
					selectorRaw: {
						code: { $regex: VUE_APP_MODULE_PATOGENS_FILTER_REGEX },
					},
				},
			]

		let i = config.findIndex((e) =>
			e.collectionName === collectionName && e?.range === range
				? true
				: false
		)

		if (i === -1)
			throw new Error(
				`[getFilterForCollection] no config for collection '${collectionName}' and range '${range}'`
			)
		else {
			const { filterDate, selectorRaw, filterDate2, selectorV2 } = config[i] || {}
			let selector = { ...selectorRaw }

			if (filterDate2?.dataProperty) {
				if (typeof filterDate2?.dataProperty == 'object') {
					const or = filterDate2?.dataProperty.map((dP) => {
						const o = {
							[dP]: {
								$gte: getDate(filterDate2.begin),
								$lte: getDate(filterDate2.end),
							},
						}

						return o
					})

					if (selector.$or && !selector.$and) {
						selector.$and = []

						selector.$and.push({
							$or: or,
						})
						selector.$and.push({
							$or: selector.$or,
						})

						delete selector.$or
					} else if (!selector.$or)
						selector.$or = [...(selector.$or || []), ...or]
				}
			} else if (filterDate?.dataProperty) {
				let code = null

				if (filterDate?.aiRange) code = 'ai'

				const dateFromPast = getDateFromPastV2(
					filterDate.peroid,
					filterDate.unit,
					false,
					code
				)

				const customBegin = getAsDateTime(
						'VUE_APP_OPENAI_CUSTOM_RANGE_BEGIN'
					),
					customEnd = getAsDateTime('VUE_APP_OPENAI_CUSTOM_RANGE_END')

				if (typeof filterDate?.dataProperty == 'string') {
					selector[filterDate.dataProperty] = {
						$gte: dateFromPast,
						// $lte: getDateNow(),
					}

					if (filterDate?.aiRangeOnlyOld)
						selector[filterDate.dataProperty] = {
							$lte: dateFromPast,
						}
					else if (filterDate?.aiRangePlusFuture)
						selector[filterDate.dataProperty] = {
							$gte: dateFromPast,
						}

					if (code === 'ai') {
						if (customEnd && !filterDate?.aiRangeOnlyOld)
							selector[filterDate.dataProperty]['$lte'] =
								moment(customEnd).format(retFormat)
						if (customBegin && !filterDate?.aiRangePlusFuture)
							selector[filterDate.dataProperty]['$gte'] =
								moment(customBegin).format(retFormat)
					}
				} else if (typeof filterDate?.dataProperty == 'object') {
					const or = filterDate?.dataProperty.map((dP) => {
						let o = {
							[dP]: {
								$gte: dateFromPast,
								// $lte: getDateNow(),
							},
						}

						if (filterDate?.aiRangeOnlyOld)
							o[dP] = {
								$lte: dateFromPast,
							}
						else if (filterDate?.aiRangePlusFuture)
							o[dP] = {
								$gte: dateFromPast,
							}

						if (code === 'ai') {
							if (customEnd && !filterDate?.aiRangeOnlyOld)
								o[dP]['$lte'] =
									moment(customEnd).format(retFormat)
							if (customBegin && !filterDate?.aiRangePlusFuture)
								o[dP]['$gte'] =
									moment(customBegin).format(retFormat)
						}

						return o
					})

					if (selector.$or && !selector.$and) {
						selector.$and = []

						selector.$and.push({
							$or: or,
						})
						selector.$and.push({
							$or: selector.$or,
						})

						delete selector.$or
					} else if (!selector.$or)
						selector.$or = [...(selector.$or || []), ...or]
				}
			} else if(selectorV2){
				selector = getSelectorV2(selectorV2)
			}

			console.debug(
				`[getFilterForCollection]`,
				collectionName,
				range,
				selector
			)

			return selector
		}
	},
	getFirst = function (arr, amount) {
		return arr?.slice(0, amount) || arr
	},
	getDaysBetweenDates = function (startDate, endDate) {
		var now = startDate.clone(),
			dates = []

		while (now.isSameOrBefore(endDate)) {
			dates.push(now.format('YYYY-MM-DD'))
			now.add(1, 'days')
		}
		return dates
	},
	codesDesc = ['opis', '__DESC__'],
	escapeRegExp = (val) => {
		if(typeof val === 'string')
			return val?.replace(
				/[-[\]{}()*+?.,\\^$|#\s]/g,
				'\\$&'
			)
		return val
	},
	isHideDescAttr = function (attributes = []) {
		// skip description
		const descIndex = attributes.findIndex((it) =>
			codesDesc.includes(it.code)
		)

		let isHideDescAttrDecision = false

		if (descIndex > -1) {
			// count rows in description
			const paramRegex = new RegExp(`[a-z\\dA-Z\\+\\-()#%]*\\s+\\t-?(\\d+[\\,\\.]?\\d+|[a-zA-ZóążźćśęłńŚŹŻÓĄĆĘŁŃóążźćśęłń]*)\\s\\t`, 'gmi'),
				paramWrongRegex = new RegExp(`^\\s?[a-zA-ZóążźćśęłńŚŹŻÓĄĆĘŁŃóążźćśęłń\\s]*\\s?$`, 'gmi')
			
			const descAttr = attributes[descIndex],
				descValue = descAttr.value,
				rows = descValue?.split('\r\n').filter((r) => r !== '' && !paramWrongRegex.test(r))
				
			if (rows?.length === attributes.length - 1 && attributes.length > 1) {
				// check rows in desc
				const items = attributes.filter(
					(it) => !codesDesc.includes(it.code)
				)

				const regexes = []
				items.forEach((it) => {
					const name = escapeRegExp(it?.name),
						value = escapeRegExp(it?.value),
						regex = new RegExp(`${name}\\s.*${value}`, 'gmi')

					regexes.push(regex)
				})
				
				let regexesFound = []
				let testPassed = true
				rows.forEach((r) => {
					const regexesMatched = regexes.filter((rx) => rx.test(r))
					regexesFound = [...new Set([...regexesFound, ...regexesMatched])]
					
					if (regexesMatched.length !== 1)
						testPassed = false
				})
				
				if(!testPassed && regexesFound.length > 0){
					//test 2
					const matches = descValue.match(paramRegex);
					if (matches && matches.length === regexes.length) {
						testPassed = true;
					}
				}
				
				if(!testPassed){
					//test 3
					if(items?.length === 1){ // only one param
						const item = items[0]
						const value = escapeRegExp(item?.value),
							regex = new RegExp(`\\s?${value}\\s?`, 'gmi')
						if(regex.test(descValue))
							testPassed = true;
					}
				}
				

				if (testPassed) {
					isHideDescAttrDecision = true

					console.debug(
						`attr description hidden - a duplicate of the data in the rest of the parameters`
					)
				}
			}
		}

		return isHideDescAttrDecision
	}

export default limitObjects
