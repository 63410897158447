const couchUrlPrefix = process.env.VUE_APP_API_COUCH
console.log('couchUrlPrefix: ' + couchUrlPrefix)

import { getAsBool, getAsInt } from '@/functions/env'

const VUE_APP_MODULE_STAYS_ROOM_INTERNAL_ENABLED = getAsBool(
		'VUE_APP_MODULE_STAYS_ROOM_INTERNAL_ENABLED'
	), VUE_APP_MODULE_ZWR_ENABLED = getAsBool('VUE_APP_MODULE_ZWR_ENABLED'),
	VUE_APP_MODULE_DRUG_APPLICATION_INTERNAL_ENABLED = getAsBool('VUE_APP_MODULE_DRUG_APPLICATION_INTERNAL_ENABLED'),
	VUE_APP_MODULE_EXAMINATION_CREATE_CN_ENABLED = getAsBool('VUE_APP_MODULE_EXAMINATION_CREATE_CN_ENABLED'),
	VUE_APP_MODULE_PATOGENS_ENABLED = getAsBool('VUE_APP_MODULE_PATOGENS_ENABLED')

const common = {
	waitForLeadership: true,
	options: {
		live: false,
		retry: false,
		batches_limit: 999999,
		//timeout: 5 * 60 * 1000
		checkpoint: 'target',
	},
	direction: {
		// read only
		pull: true,
		push: false,
	},
	allowedRules: ['admin', 'doctor', 'personel_zwr', 'nurse'],
}

export const createRemote = function (tablename) {
		if (!couchUrlPrefix || couchUrlPrefix?.length == 0)
			throw new Error(
				`No couch url environment variable "VUE_APP_API_COUCH"`
			)

		let couchUrl = couchUrlPrefix + '/' + tablename

		if (couchUrl[0] === '/')
			couchUrl = `${window.location.origin}${couchUrl}`

		return couchUrl
	},
	kebabCaseToCamelCase = (string) =>
		string
			.replace(/(-[a-z])/g, (letters) => `${letters[1].toUpperCase()}`)
			.replace(/^[a-z]/g, (letters) => `${letters.toUpperCase()}`)

const getSyncConfig = function () {
	let baseSyncConfig = {
		stays: {
			modelName: 'stay',
			...common,
			options: {
				...common.options,
			},
		},
		stay_notes: {
			modelName: 'stay-note',
			...common,
			direction: {
				pull: true,
				push: true,
			},
			options: {
				...common.options,
			},
		},
		stay_note_files: {
			modelName: 'stay-note-file',
			...common,
			direction: {
				pull: true,
				push: true,
			},
			options: {
				...common.options,
			},
		},
		stay_note_roles: {
			modelName: 'stay-note-role',
			...common,
			direction: {
				pull: true,
				push: true,
			},
			options: {
				...common.options,
			},
		},
		patients: {
			modelName: 'patient',
			...common,
			options: {
				...common.options,
			},
		},
		patient_profile_images: {
			modelName: 'patient-profile-image',
			...common,
			direction: {
				pull: true,
				push: true,
			},
			options: {
				...common.options,
			},
		},
		patient_alerts: {
			modelName: 'patient-alert',
			...common,
			options: {
				...common.options,
			},
		},
		alert_attributes: {
			modelName: 'alert-attribute',
			...common,
			options: {
				...common.options,
			},
		},
		alert_attribute_codes: {
			modelName: 'alert-attribute-code',
			...common,
			options: {
				...common.options,
			},
		},
		alert_attribute_levels: {
			modelName: 'alert-attribute-level',
			...common,
			options: {
				...common.options,
			},
		},
		examinations: {
			modelName: 'examination',
			...common,
			options: {
				...common.options,
			},
		},
		examination_requests: {
			modelName: 'examination-request',
			...common,
			options: {
				...common.options,
			},
		},
		treatments: {
			modelName: 'treatment-form',
			...common,
			options: {
				...common.options,
			},
		},
		procedures: {
			modelName: 'procedure',
			...common,
			options: {
				...common.options,
			},
		},
		diagnosis: {
			modelName: 'stay-diagnosis',
			...common,
			options: {
				...common.options,
			},
		},
		drug_applications: {
			modelName: 'drug-application',
			...common,
			options: {
				...common.options,
			},
		},
		org_units: {
			modelName: 'org-unit',
			...common,
			options: {
				...common.options,
			},
		},
		org_unit_persons: {
			modelName: 'org-unit-person',
			...common,
			options: {
				...common.options,
			},
		},
		zwr_monitor_orders: {
			modelName: 'zwr-monitor-order',
			...common,
			direction: {
				pull: true,
				push: true,
			},
			options: {
				...common.options,
			},
			allowedRules: ['admin', 'personel_zwr'],
		},
		zwr_patient_alerts: {
			modelName: 'zwr-patient-alert',
			...common,
			options: {
				...common.options,
			},
			allowedRules: ['admin', 'personel_zwr'],
		},
		org_unit_room_stays: {
			modelName: 'org-unit-room-stay',
			...common,
			direction: {
				pull: true,
				push: true,
			},
			options: {
				...common.options,
			},
		},
		org_unit_rooms: {
			modelName: 'org-unit-room',
			...common,
			options: {
				...common.options,
			},
		},
		drug_application_internals: {
			modelName: 'drug-application-internal',
			...common,
			direction: {
				pull: true,
				push: true,
			},
			options: {
				...common.options,
			},
		},
		pathogen_instances: {
			modelName: 'pathogen-instance',
			...common,
			options: {
				...common.options,
			},
		},
	}
	
	Object.keys(baseSyncConfig).forEach((collectionName) => {
		const batchSize = getAsInt(`VUE_APP_RXDB_BATCH_SIZE_${collectionName.toUpperCase()}`)
		
		if(!batchSize)
			throw new Error(`No batch size environment variable "VUE_APP_RXDB_BATCH_SIZE_${collectionName.toUpperCase()}"`)
		
		baseSyncConfig[collectionName].options.batch_size = batchSize
	})

	if (!VUE_APP_MODULE_STAYS_ROOM_INTERNAL_ENABLED) {
		delete baseSyncConfig.org_unit_room_stays
		delete baseSyncConfig.org_unit_rooms
	}
	
	if(!VUE_APP_MODULE_ZWR_ENABLED){
		delete baseSyncConfig.zwr_monitor_orders
		delete baseSyncConfig.zwr_patient_alerts
	}
	
	if(!VUE_APP_MODULE_DRUG_APPLICATION_INTERNAL_ENABLED){
		delete baseSyncConfig.drug_application_internals
	}
	
	if(!VUE_APP_MODULE_EXAMINATION_CREATE_CN_ENABLED){
		delete baseSyncConfig.examination_requests
	}
	
	if(!VUE_APP_MODULE_PATOGENS_ENABLED){
		delete baseSyncConfig.pathogen_instances
	}

	return baseSyncConfig
}

export const syncConfig = getSyncConfig()

export default syncConfig
