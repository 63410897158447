import { couch_uuid } from './fields'

export const schema = {
	title: 'stay note file schema',
	description: 'describes a single stay note file',
	version: 0,
	keyCompression: false,
	primaryKey: 'couch_uuid',
	type: 'object',
	properties: {
		couch_uuid,
		stay_note_uuid: {
			type: 'string',
			ref: 'stay_notes',
		},
		filename: {
			type: 'string',
		},
		mime: {
			type: 'string',
		},
		title: {
			type: 'string',
		},
		size: {
			type: 'string',
		},
		data: {
			type: 'string',
		},
	},
	required: ['couch_uuid'],
}

export default schema
