import { keys } from '@/functions/localStorage'

export const setPendingChanges = function(value=false) {
    const key = keys.trayChangesFlag
    
    localStorage.setItem(key, value)
}

export const checkIfPendingChanges = function() {
    const key = keys.trayChangesFlag
    
    const arePendingChanges = JSON.parse(localStorage.getItem(key))
    
    return arePendingChanges
}

export const checkIfAllPendingApplied = function(drug_applications_pending=[], drug_applications_internal=[]) {
    const drug_applications_pending_ids = drug_applications_pending.map((el) => el.id),
    drug_applications_all_done = drug_applications_pending_ids?.length === drug_applications_internal?.length
    
    return drug_applications_all_done
    
}