import { couch_uuid } from './fields'

export const schema = {
	title: 'patient image schema',
	description: 'describes a single patient image',
	version: 0,
	keyCompression: false,
	primaryKey: 'couch_uuid',
	type: 'object',
	properties: {
		couch_uuid,
		filename: {
			type: 'string',
		},
		mime: {
			type: 'string',
		},
		title: {
			type: 'string',
		},
		width: {
			type: 'string',
		},
		height: {
			type: 'string',
		},
		size: {
			type: 'string',
		},
		data: {
			type: 'string',
		},
		patient_uuid: {
			...couch_uuid,
		},
	},
	required: ['couch_uuid', 'patient_uuid', 'filename', 'data', 'title'],
}

export default schema
