import { couch_uuid, id, index } from './fields'

export const schema = {
	title: 'org unit persons',
	description: 'describes a org unit person model',
	version: 0,
	keyCompression: false,
	primaryKey: 'couch_uuid',
	type: 'object',
	properties: {
		couch_uuid,
		org_unit_uuid: {
			...couch_uuid,
			ref: 'org_units',
		},
		person_id_ref: {
			...index,
			...id,
		},
	},
	required: ['couch_uuid', 'org_unit_uuid', 'person_id_ref'],
	indexes: [['org_unit_uuid', 'person_id_ref']],
}

export default schema
