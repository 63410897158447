import { couch_uuid, id, /* index */ } from './fields'

export const schema = {
	title: 'org unit room stays',
	description: 'describes a org unit room stay model',
	version: 0,
	keyCompression: false,
	primaryKey: 'couch_uuid',
	type: 'object',
	properties: {
		couch_uuid,
		org_unit_room_couch_uuid: {
			...couch_uuid,
			ref: 'org_unit_rooms',
			final: false,
		},
		person_id_ref: {
			// ...index,
			...id,
		},
		stay_id_ref: {
			// ...index,
			...id,
		},
		start_date: {
			type: 'string',
			// final: true,
		},
	},
	required: ['couch_uuid', 'org_unit_room_couch_uuid', 'person_id_ref', 'stay_id_ref', 'start_date'],
	// indexes: [],
}

export default schema
