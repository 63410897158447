import { couch_uuid, id, index } from './fields'

import { getAsBool } from '@/functions/env'

const VUE_APP_E_T_PER_HOSPITALIZATION_ENABLED = getAsBool(
	'VUE_APP_E_T_PER_HOSPITALIZATION_ENABLED'
)

const getIndexes = function () {
	let indexes = [
		['stay_id_ref', 'examination_date'],
		['stay_id_ref', 'order_date'],
	]

	if (VUE_APP_E_T_PER_HOSPITALIZATION_ENABLED) {
		indexes = [
			...indexes,
			['hospitalisation_id_ref', 'examination_date'],
			['hospitalisation_id_ref', 'order_date'],
		]
	}

	return indexes
}

export const schema = {
	title: 'examination schema',
	description: 'describes a single examination',
	version: 0,
	keyCompression: false,
	primaryKey: 'couch_uuid',
	type: 'object',
	properties: {
		couch_uuid,
		id: {
			...id,
		},

		// persistent id
		stay_id_ref: {
			...id,
		},

		title: {
			type: 'string',
		},
		examination_date: {
			...index,
			type: 'string',
		},
		gui_type: {
			type: ['string', 'null'],
		},
		order_date: {
			...index,
			type: 'string',
		},
		performing_unit_gui_type: {
			type: ['string', 'null'],
		},
		result_form_id: {
			type: ['integer', 'null'],
		},
		result: {
			type: ['string', 'null'],
		},
		service_id_ref: {
			...id,
		},
		service_name: {
			type: 'string',
		},
		status_name: {
			type: 'string',
		},
		result_form_contain_news: {
			type: 'boolean',
		},
		stay_orgunit_code: {
			type: 'string',
		},
		hospitalisation_id_ref: {
			...id,
		},
			
		remote_id: {
			...id,
		},
	},
	required: ['couch_uuid'],
	indexes: getIndexes(),
}

export default schema
