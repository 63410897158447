import { couch_uuid, id } from './fields'

export const schema = {
	title: 'patient schema',
	description: 'describes a single patient',
	version: 0,
	keyCompression: false,
	primaryKey: 'couch_uuid',
	type: 'object',
	properties: {
		couch_uuid,
		id: {
			...id,
		},
		first_name: {
			type: ['string', 'null'],
		},
		middle_name: {
			type: ['string', 'null'],
		},
		last_name: {
			type: ['string', 'null'],
		},
		birth_date: {
			type: ['string', 'null'],
			final: true,
		},
		sex_code: {
			type: ['string', 'null'],
		},
		patient_no: {
			type: ['string', 'null'],
		},		
		remote_id: {
			...id,
		},
	},
	required: ['couch_uuid', 'id'],
	index: ['couch_uuid'],
}

export default schema
