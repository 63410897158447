import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'

import { $classes } from '@/main'
// import { translate } from '@/i18n'

import { createEventFromCode, createEvent } from '@/functions/events'
import { isOnline } from '@/functions/network'
import { setAuth, getTokenBy, setEncryptedAuth, getDecryptedAuth } from '@/functions/auth'
import { sendPendingReports } from '@/functions/reportProblem'
import { isQuotaExceededError, quotaExceededErrorResolution, sanitizeError } from '@/functions/error'
import { keys } from '@/functions/localStorage'
import {
	getToken,
	set_RxDB_auth,
	compare_user_password,
	is_offline_login_avaliable,
	clear_auth_data,
	set_RxDB_auth_offline,
	sa_compare_password,
	getUserLogin,
} from '@/functions/auth'
import { removeCalculatedParams } from '@/functions/paramsOffline'
import { getCircularReplacer } from '@/functions/json'
import { isEnvDevelopment } from '@/functions/env'

import DatabaseService from '@/services/Database.service.js'
import STTService from '@/services/STT.service.js'

@Module({ namespaced: true })
class CurrentUser extends VuexModule {
	instance = null
	sms_request_id = null
	onesignal_skd_initialized = false
	key = keys.user
	network_status = 'online'

	@Mutation
	setInstance(user) {
		this.instance = user
	}

	@Mutation
	setNetworkStatus(status) {
		this.network_status = status
	}

	@Mutation
	setOneSignalInitialized() {
		this.onesignal_skd_initialized = true
	}

	@Action({ rawError: true })
	async clearRxDB() {
		console.debug(`[clearRxDB] initializing remove database procedure...`)

		/* RxDB */
		await (await DatabaseService.singleton()).removeDatabase()

		console.debug(`[clearRxDB] finished`)
	}

	@Action({ rawError: true })
	async removeCalculatedParams() {
		removeCalculatedParams()
	}
	
	@Action({ rawError: true })
	async updateNetworkStatusWithError() {
		try {
			await isOnline()
			
			if(this.network_status === 'offline'){
				await createEventFromCode(
					'network-internet-connection-back-success'
				)
				await sendPendingReports()
			}
			
			this.context.commit('setNetworkStatus', 'online')
		} catch (e) {
			const wasOnline = this.network_status === 'online'
			
			if(wasOnline) {
				this.context.commit('setNetworkStatus', 'offline')
				throw e
			}
		}
	}

	@Action({ rawError: true })
	async updateNetworkStatus() {
		try {
			await this.context.dispatch('updateNetworkStatusWithError')
		} catch (e) {
			console.warn(e)
		}
	}

	@Mutation
	saveUserDataWithoutAuth(data) {
		const key = this.key

		let dataOld = localStorage.getItem(key)
				? JSON.parse(localStorage.getItem(key))
				: {},
			dataNew = {
				...dataOld,
				...data,
				last_update: new Date().toLocaleTimeString('pl-PL'),
			}

		let dataStringified = JSON.stringify(dataNew, getCircularReplacer())

		/* localstorage */
		try {
			localStorage.setItem(key, dataStringified)
		} catch (e) {
			if (isQuotaExceededError(e)) quotaExceededErrorResolution(e)
			else throw e
		}
	}

	@Mutation
	saveUserData(data) {
		const key = this.key

		let { auth } = data,
			{
				access_token,
				refresh_token,
				expires_in,
				refresh_expires_in,
				// remember_me,
			} = auth,
			access_token_expire = Date.now() + expires_in,
			access_token_created = Date.now(),
			refresh_token_expire = Date.now() + refresh_expires_in,
			refresh_token_created = Date.now()

		let dataOld = JSON.parse(localStorage.getItem(key) || '{}'),
			dataNew = {
				...dataOld,
				...data,
				auth: {
					...data.auth,

					access_token,
					refresh_token,
					access_token_expire,
					access_token_created,
					refresh_token_expire,
					refresh_token_created,
				},
				last_update: new Date().toLocaleTimeString('pl-PL'),
			}

		let dataStringified = JSON.stringify(dataNew, getCircularReplacer())

		/* localstorage */
		try {
			localStorage.setItem(key, dataStringified)
		} catch (e) {
			if (isQuotaExceededError(e)) quotaExceededErrorResolution(e)
			else throw e
		}
	}

	@Mutation
	clearUserData() {
		// delete only auth
		if (localStorage.getItem(this.key)) {
			const dataOld = JSON.parse(localStorage.getItem(this.key)),
				dataNew = {
					instance: dataOld.instance,
					last_update: dataOld.last_update,
				}

			/* localstorage */
			try {
				localStorage.setItem(this.key, JSON.stringify(dataNew))
			} catch (e) {
				if (isQuotaExceededError(e)) quotaExceededErrorResolution(e)
				else throw e
			}
		}
		
		clear_auth_data()
	}

	@Mutation
	restoreUserData() {
		const key = this.key

		if (localStorage.getItem(key)) {
			let data = JSON.parse(localStorage.getItem(key))

			if (data?.instance?.id) {
				console.debug(
					`[restoreUserData] Restoring user data from cache`
				)
				this.instance = data.instance
			} else console.debug(`[restoreUserData] No user data in cache.`)

		}
	}

	@Action({ rawError: true })
	async requireUserInfo() {
		if (this.instance === null) {
			await this.context.dispatch('requirePublicClasses')

			console.debug(`[requireUserInfo] Getting current user instance...`)
			const user = await $classes?.Person?.current_user() //TODO add condition
			if (user?.__id) {
				this.context.commit('setInstance', user)
				await this.context.dispatch('requireAuthenticatedClasses')

				console.debug(`[requireUserInfo] success.`)
			} else {
				console.debug(`[requireUserInfo] fail.`)
			}
		}
	}

	@Action({ rawError: true })
	async requireAuthenticatedClasses() {
		const token = await getToken()

		if (token) {
			console.debug(
				`[requireAuthenticatedClasses] started`
			)
			await $classes.loadAuthenticated({ token: token })
			
			console.debug(
				`[requireAuthenticatedClasses] finished`
			)
		} else {
			console.debug(
				`[requireAuthenticatedClasses] no token`
			)
		}
	}

	@Action({ rawError: true })
	async requirePublicClasses() {
		if (!$classes?.Person) {
			console.debug(
				`[requirePublicClasses] started`
			)
			try {
				await $classes.loadPublic()
				console.debug(
					`[requirePublicClasses] finished`
				)
			} catch (e) {
				console.log({ ...e })
			}
		}
	}

	@Action({ rawError: true })
	async get_user_data_from_cache() {
		if (this.instance == null) {
			this.context.commit('restoreUserData')
		}
	}

	@Action({ rawError: true })
	async is_offline_login_avaliable() {
		console.debug(`[is_offline_login_avaliable] checking...`)

		if (is_offline_login_avaliable()) return true

		return false
	}

	@Action({ rawError: true })
	async compare_offline_logged_user_to_online_access_data({ login, pass, saHash }) {
		console.debug(
			`[compare_offline_logged_user_to_online_access_data] checking data...`
		)

		const initClearRxDB =
			compare_user_password({
				userPassPlain: pass,
				userEmailPlain: login,
			}) === false && sa_compare_password({ saHash }) === false 
			
		const useForeignDB = compare_user_password({
			userPassPlain: pass,
			userEmailPlain: login,
		}) === false && sa_compare_password({ saHash })
		
		if(useForeignDB)
			await createEvent({
				status: 'Success',
				details: `Użytkownik posiada dostęp do bazy danych zsynchronizowanej przez ${getUserLogin()}`,
			})
			

		if (initClearRxDB) {
			await this.context.dispatch('clearRxDB')

			//TODO reset repliactions
			await this.context.dispatch(
				'CurrentDatabase/resetReplicationInfo',
				null,
				{ root: true }
			)
		}
	}

	@Action({ rawError: true })
	async userDataUpdate() {
		const token = await getToken()

		if (token) {
			await $classes.loadAuthenticated({ token: token })
			const user = await $classes.Person.current_user()

			this.context.commit('saveUserDataWithoutAuth', {
				instance: user,
			})
			this.context.commit('setInstance', user)
		} else
			throw new Error(
				`Access token and refresh token expired, please login by online method.`
			)
	}

	@Action({ rawError: true })
	async login({ login, pass, remember_me, account_type }) {
		if(isEnvDevelopment)
			console.debug(login, remember_me, account_type)

		// czyszczenie niepotrzebnych danych
		this.context.dispatch('removeCalculatedParams')
		
		let token = null

		try {
			token = await getTokenBy({login, pass})
		} catch (error) {
			if (!error?.response) {
				throw new Error(
					`Login online method is out of service, please check internet access or login by offline method.`
				)
			} else {
				// createEvent({
				// 	status: 'Fail',
				// 	details: error?.response?.data?.error_description && translate(error?.response?.data?.error_description) || 'Nieznany błąd',
				// 	debug: sanitizeError(error),
				// })
				
				throw sanitizeError(error)
			}
		}

		if (token) {
			const {
				access_token,
			} = token

			await $classes.loadAuthenticated({ token: access_token })
			const user = await $classes.Person.current_user(),
				rxdb_config = await $classes.Person.get_rxdb_config(),
				{ assigned_org_units_hash } = user

			this.context.commit('clearUserData')

			await this.context.dispatch(
				'compare_offline_logged_user_to_online_access_data',
				{ login, pass, saHash: assigned_org_units_hash }
			)
			
			set_RxDB_auth({
				userPassPlain: pass,
				userEmailPlain: login,
				rxdbPasswordPlain: rxdb_config?.rxdb_password,
				
				//SA
				saHash: assigned_org_units_hash
			})
			
			setAuth(token)
			setEncryptedAuth(token, `${login}${pass}`)
			
			const userTransformed = {
				...user,
				login: user?.login || user?.ldap_login,
			}
			
			this.context.commit('saveUserDataWithoutAuth', {
				instance: {
					id: userTransformed.id,
					login: userTransformed.login,
					role: userTransformed.role,
					first_name: userTransformed.first_name,
					last_name: userTransformed.last_name,
					ldap_login: userTransformed.ldap_login,
				},
			})
			
			this.context.commit('setInstance', userTransformed)

			await createEventFromCode('user-login-online-success')
		}
	}

	@Action({ rawError: true })
	// eslint-disable-next-line no-unused-vars
	async login_offline({ login, pass }) {
		console.debug(`[login_offline] checking password...`)

		if (
			compare_user_password({
				userPassPlain: pass,
				userEmailPlain: login,
			}) === true
		) {
			console.debug(`[login_offline] password ok`)
			this.context.commit('restoreUserData')
			set_RxDB_auth_offline({ userPassPlain: pass, userEmailPlain: login })
			setAuth(getDecryptedAuth(`${login}${pass}`))

			await createEventFromCode('user-login-offline-success')
		} else {
			await createEventFromCode('user-login-offline-fail')

			throw new TypeError(
				'Wrong password, please try again or login by online method.'
			)
		}
	}

	@Action({ rawError: true })
	async login_with_token() {
		console.debug(`[login_with_token] starting...`)

		const token = await getToken()

		if (token) {
			await $classes.loadAuthenticated({ token: token })
			const user = await $classes.Person.current_user()

			this.context.commit('saveUserDataWithoutAuth', {
				instance: user,
			})
			this.context.commit('setInstance', user)

			await createEventFromCode('user-login-online-success')

			console.debug(`[login_with_token] logged`)
		} else {
			console.debug(`[login_with_token] no token`)
		}
	}

	@Action({ rawError: true })
	async proxy_login({ accessToken }) {
		await $classes.loadAuthenticated({ token: accessToken })
		const user = await $classes.Person.current_user()

		this.context.commit('setInstance', user)
	}

	@Action({ rawError: true })
	async logout() {
		console.debug('[logout] initializing...')

		if ($classes?.Person)
			try {
				await $classes.Person?.logout()
			} catch (e) {
				console.warn(e)
			}
		
		// stt
		await (await STTService.singleton())?.stopCRA()
		
		/* RxDB */
		const db = await DatabaseService.singleton()
		await db?.onUserLogout()
		
		// czyszczenie niepotrzebnych danych
		this.context.dispatch('removeCalculatedParams')
		
		this.context.dispatch('CurrentStays/resetSelectedUnitRoom', null, { root: true })
		
		await createEventFromCode('user-logout-success')
		
		this.context.commit('setInstance', null)
		this.context.commit('clearUserData')

		console.debug('[logout] done.')
	}

	@Action({ rawError: true })
	async get_user_data() {
		const user = await $classes.Person.current_user()
		this.context.commit('setInstance', user)
	}

	@Action({ rawError: true })
	initOneSignalSDK() {
		if (
			!this.onesignal_skd_initialized &&
			this.instance &&
			this.instance.role == 'Runner'
		) {
			window.OneSignal = window.OneSignal || []
			window.OneSignal.push(() => {
				window.OneSignal.init({
					appId: process.env.VUE_APP_ONESIGNAL_APP_ID,
					allowLocalhostAsSecureOrigin:
						process.env.NODE_ENV !== 'production',
				})
			})

			console.log('onesignal sdk initialized')

			this.context.commit('setOneSignalInitialized')
		}
	}
	
	@Action({ rawError: true })
	async setOrgUnitsList(assigned_org_units) {
		await this.context.dispatch('requireAuthenticatedClasses')
		await $classes.Person.set_org_unit_list({assigned_org_units: assigned_org_units})
	}
}

export default CurrentUser
