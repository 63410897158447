import { couch_uuid, id, index } from './fields'

import { getAsBool } from '@/functions/env'

const VUE_APP_MODULE_STAYS_ROOM_EXTERNAL_ENABLED = getAsBool(
	'VUE_APP_MODULE_STAYS_ROOM_EXTERNAL_ENABLED'
)

const getProperties = () => {
	let properties = {
		couch_uuid,
		id: {
			...id,
		},
		hospitalisation_book_identifier: {
			type: 'string',
			final: true,
		},
		ward_book_identifier: {
			type: 'string',
			final: true,
		},
		patient_uuid: {
			...couch_uuid,
			ref: 'patients',
		},
		org_unit_code: {
			...index,
			type: 'string',
		},
		sub_org_unit_code: {
			...index,
			type: 'string',
		},
		org_unit_uuid: {
			...couch_uuid,
		},
		lead_doctor: {
			type: ['object', 'null'],
			properties: {
				first_name: {
					type: ['string', 'null'],
				},
				last_name: {
					type: ['string', 'null'],
				},
			},
		},
		start_date: {
			type: 'string',
			final: true,
		},
		stop_date: {
			type: ['string', 'null'],
		},
		hospitalisation_id_ref: {
			...id,
		},

		// VUE_APP_MODULE_STAYS_ROOM_EXTERNAL_ENABLED
		room: {
			type: 'string',
		},
		bed: {
			type: 'string',
		},
		
		remote_id: {
			...id,
		},
	}

	if (!VUE_APP_MODULE_STAYS_ROOM_EXTERNAL_ENABLED) {
		delete properties.room
		delete properties.bed
	}

	return properties
}

const schema = {
	title: 'stay schema',
	description: 'describes a single stay',
	version: 0,
	keyCompression: false,
	primaryKey: 'couch_uuid',
	type: 'object',
	properties: getProperties(),
	required: ['couch_uuid', 'org_unit_code', 'id'],
	indexes: [['org_unit_code', 'couch_uuid']],
}

export default schema
