const stringSimilarity = require('string-similarity')

const minRating = 0.5

export const getSimilarity = (str1 = null, str2 = null) => {
		if (!str1 || !str2) throw new Error('No str1 or str2')

		const similarity = stringSimilarity.compareTwoStrings(str1, str2)

		return similarity
	},
	getBestMatch = (str = null, arr = []) => {
		if (!str) throw new Error('No str')
		if (arr.length < 1) throw new Error('Arr length < 1')

		const matches = stringSimilarity.findBestMatch(str.toLowerCase(), arr)
		
		console.debug(`[getBestMatch]`, matches)

		return matches
	},
	getBestMatchIndex = (str = null, arr = []) => {
		let s = str
		if(s && s[str.length - 1] === '.')
			s = s.slice(0, -1)
	
	    const bestMatch = getBestMatch(s, arr)?.bestMatch
	    const {target, rating} = bestMatch
	    
	    if(rating >= minRating){
            console.debug(`[getBestMatchIndex]`, bestMatch)
	        return arr.indexOf(target)
	    }
	    return null
	}

export default getSimilarity