import { v4 as uuidv4 } from 'uuid'

import { $classes } from '@/main'
import store from '@/store'

import { createEvent, createEventFromCode } from '@/functions/events'
import { isOnline } from '@/functions/network'
import { promiseAllSynchronous } from '@/functions/promise'
import { getErrorMessage, isQuotaExceededError, quotaExceededErrorResolution } from '@/functions/error.js'
import { keys } from '@/functions/localStorage'

import { getAsBool } from '@/functions/env'

const VUE_APP_MODULE_ERRORS_REPORTNG_ENABLED = getAsBool('VUE_APP_MODULE_ERRORS_REPORTNG_ENABLED')

const fillReport = function (report = {}) {
		report.console_log = console.storedLogs
		report.sync_log = store.state.CurrentLog.events
		report.connection_status = store.state.CurrentUser.network_status
		report.last_commit_hash = process.env.VUE_APP_COMMIT_HASH
		report.app_version = store.getters.appVersion

		if (!report.email && store.state.CurrentUser?.instance?.login)
			report.email = store.state.CurrentUser.instance.login
		if (!report.url) report.url = window.location.href
		if (!report.submission_method) report.submission_method = 'manual'

		if (!report.files) report.files = []

		if (!report.creation_date) report.creation_date = new Date()
		if (!report.title) report.title = 'Zgłoszenie uzytkownika'
		if(!report.user_agent) report.user_agent = navigator?.userAgent

		report.uuid = uuidv4()

		return report
	},
	validateReport = function (report = {}) {
		const requiredProps = [
			'console_log',
			'sync_log',
			'connection_status',
			'url',
			'last_commit_hash',
			'app_version',
			// 'email', //ANONYMOUS
		]

		requiredProps.forEach((prop) => {
			if (!Object.keys(report || {}).includes(prop))
				throw new Error(`Missing required '${prop}' in report.`)
		})
	},
	keyPendingReports = keys.pendingReports,
	JSONStringifyFixed = function (val) {
		try {
			val = val?.filter(item => {
				if (item && Array.isArray(item)) {
					const deleteThisItem = item.every(item2 => item2 && item2.console_log)
						
					if(deleteThisItem)
						return false
				}
				return true
			})
			return JSON.stringify(val)
		} catch (e) {
			console.error(e)
			console.debug(val)
		}
	},
	setPendingReports = function (value = []) {
		try {
			localStorage.setItem(keyPendingReports, JSONStringifyFixed(value))
		} catch (e) {
			if (isQuotaExceededError(e)) quotaExceededErrorResolution(e)
			else throw e
		}
	},
	getPendingReports = function () {
		const json = localStorage.getItem(keyPendingReports)
		
		if (json && json !== 'undefined') return JSON.parse(json)
		
		return []
	},
	isPendingReportExist = function (report = {}, reports = []) {
		const isExist =
			reports.findIndex((reportE) => reportE?.title === report?.title) >
			-1

		return isExist
	},
	appendPendingReport = function (report = {}) {
		const reports = getPendingReports(),
			isExist = isPendingReportExist(report, reports)

		if (!isExist) {
			reports.push(report)
			setPendingReports(reports)
		} else
			console.warn(
				`[appendPendingReport] report exists in pending reports, preventing duplication`
			)
	},
	markPendingReportAsSend = function (report = {}) {
		const reports = getPendingReports(),
			reportIndex = reports.findIndex((r) => r.uuid === report.uuid)

		reports.splice(reportIndex, 1)

		setPendingReports(reports)
	},
	sendPendingReport = async function (report = {}) {
		await sendReport(report)
		markPendingReportAsSend(report)
	},
	sendReport = async function (report = {}) {
		if(!VUE_APP_MODULE_ERRORS_REPORTNG_ENABLED){
			console.debug(`[sendReport] reporting is disabled`)
			return
		}
		
		try {
			await store.dispatch('CurrentUser/requireAuthenticatedClasses')

			if(!$classes?.ReportProblem?.create_report)
				throw new Error('[sendReport] no classes')

			const response = await $classes.ReportProblem?.create_report({
				data: report,
			})
			if (console.storedLogs) console.storedLogs.length = 0

			await createEvent({
				status: 'Success',
				details: `Utworzono zgłoszenie problemu o ID.: ${response}`,
			})
		} catch (e) {
			const { message } = e
			
			// const isOnline =  store.state.CurrentUser.network_status === 'online'
			const is40X = isExceptionCheckRegex(message, /Request failed with status code 4\d\d/)
			
			appendPendingReport(report)
			await createEventFromCode('report-problem-failed-send-later')
			
			if (!isExceptionCheck(message) && !is40X) console.error(message)
		}
	},
	exceptions = [
		// network 
		'No internet access.',
		'No connection to the server.',
		'Connection speed is to low.',
		// /Request failed with status code*./,
		'Network Error',
		
		// reports
		'[sendReport] no classes',
		
		// app version
		'Wykryto nową wersję aplikacji, proszę o zaktualizowanie',
		
		// db
		`DatabaseService: database password don't exists. Please login by online method.`,
		
		// azure
		'[getAuthOA] no classes',
		'[getAuthSS] no classes',
		'[getAuthSS] no get_app_azure_token_speech_service',
		'[getAuthOA] no get_app_azure_token_open_ai',
		
		// stt
		// /CANCELED: ErrorCode=4*./,
		'CANCELED: ErrorCode=4, ErrorDetails=Unable to contact server. StatusCode: 0, undefined Reason: ',
		'CANCELED: ErrorCode=4, ErrorDetails=Unable to contact server. StatusCode: 1006, undefined Reason:  undefined',
		
		// SSL
		/An SSL certificate error/i,
		/Error during service worker registration/i,
		/Failed to register a ServiceWorker/i,
	]

export const createReport = async function (report = {}) {
		try {
			report = fillReport(report)
			validateReport(report)

			await store.dispatch('CurrentUser/updateNetworkStatusWithError')

			return await sendReport(report)
		} catch (e) {
			const { message } = e

			appendPendingReport(report)
			await createEventFromCode('report-problem-failed-send-later')

			if (!isExceptionCheck(message)) console.error(message)
		}
	},
	sendPendingReports = async function () {
		try {
			await isOnline()

			const reports = getPendingReports()
			
			if(reports?.length > 0)
				await createEventFromCode('report-problem-sending-pending')
			
			
			await promiseAllSynchronous(reports, sendPendingReport)
		} catch (e) {
			console.error(e)
		}
	},
	isExceptionCheckRegex = (message, ex) => {
		if (ex.test(message)) return true
		
		return false
	},
	isExceptionCheckString = (message, str) => {
		if (str.includes(message)) return true
		
		return false
	},
	isExceptionCheck = (message) => {
		const exceptionsString = exceptions.filter(
				(ex) => typeof ex === 'string'
			),
			exceptionsRegex = exceptions.filter((ex) => ex instanceof RegExp)
			
		const isExceptionString = isExceptionCheckString(message, exceptionsString),
			isExceptionRegex = exceptionsRegex.some((ex) => isExceptionCheckRegex(message, ex))

		if(isExceptionString || isExceptionRegex)
			return true

		return false
	},
	isString = function (x) {
		return Object.prototype.toString.call(x) === '[object String]'
	},
	errorToReport = async function (err = {}) {
		try {
			let { message } = err

			if (typeof message !== 'undefined') {
				const { stack, name } = err
				
				console.debug(`[errorToReport]`, {...err})

				message = getErrorMessage(message)
				
				const isException = isExceptionCheck(message)

				if (!isException) {
					console.debug(
						`[errorToReport] creating report for error: ${message}`, {...err}
					)

					await createReport({
						title: `Wystąpił błąd: ${message}`,
						message: `Name: ${name} | Stack: ${stack}`,
						submission_method: 'automatic',
					})
				} else
					console.debug(
						`[errorToReport] exception found for: ${message}`
					)
			} else if (isString(err)) {
				const isException = isExceptionCheck(err)
				
				console.debug(`[errorToReport]`, err)

				if (!isException) {
					console.debug(
						`[errorToReport] creating report for error: ${err}`
					)

					await createReport({
						title: `Wystąpił błąd: ${err}`,
						message: `${err}`,
						submission_method: 'automatic',
					})
				} else
					console.debug(`[errorToReport] exception found for: ${err}`)
			}
		} catch (e) {
			console.warn(e)
		}
	}
