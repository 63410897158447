
import PouchDBCore from 'pouchdb-core';
let origbulkDocs = PouchDBCore.prototype.bulkDocs;

if (origbulkDocs.name == 'newBulkDocs') console.error('PouchDBCore.prototype.bulkDocs is already overwritten!')

PouchDBCore.prototype.bulkDocs = function (docs, opts, callback) {
    if (this._wrapperBulkDocs) {
        //console.log('calling _wrapperBulkDocs', docs, opts)
        this._originals.bulkDocs = origbulkDocs;
        return this._wrapperBulkDocs(docs, opts, callback);
    } else {
        //console.log('calling origBulkDocs', docs, opts)
        return origbulkDocs.call(this, docs, opts, callback)
    }
}

export default {
    origbulkDocs: origbulkDocs
}
