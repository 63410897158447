import { couch_uuid, id, index } from './fields'

export const schema = {
	title: 'pathogen instance',
	description: 'describes a pathogen instances',
	version: 0,
	keyCompression: false,
	primaryKey: 'couch_uuid',
	type: 'object',
	properties: {
		couch_uuid,
		id: { ...id },
		patient_uuid: {
			...couch_uuid,
		},
        creation_date: {
			...index,
            type: 'string'
        },
        external_value: {
            type: ['string', 'null']
        },
        name: {
            type: ['string', 'null']
        },
        fullname: {
            type: ['string', 'null']
        },
        code: {
            type: ['string', 'null']
        },
	},
	required: ['couch_uuid', 'patient_uuid', 'creation_date'],
	indexes: [
	    ['patient_uuid'],
	    ['patient_uuid', 'creation_date']
	],
}

export default schema
