import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'

import routes from '@/router/routes'

import { is_hash_user_pass_hash_exists } from '@/functions/auth'

import { getOldestSyncInfo, pendingSyncTimePassed } from '@/functions/sync'

import DatabaseService from '@/services/Database.service.js'
import { get_rxdb_password } from '@/services/Database.service.js'

import { is_auth_not_expired } from '@/functions/auth'
import { getAsBool, getAsInt } from '@/functions/env'
import { checkIfPendingChanges, setPendingChanges } from '@/functions/tray'

Vue.use(VueRouter)

const scrollPositions = {},
	delay = ms => new Promise(resolve => setTimeout(resolve, ms)),
	savedPositionBase = { 
		// behavior: 'smooth' 
	}

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes, 
	scrollBehavior: async (to, from, savedPosition) => {
        await delay(100)

        if (savedPosition)
            return { ...savedPosition, ...savedPositionBase }
        else if (to.hash) {
            const element = document.querySelector(to.hash)
            if (element)
                return { top: element.offsetTop, ...savedPositionBase }
        } else if (scrollPositions[to.fullPath])
            return { ...scrollPositions[to.fullPath], ...savedPositionBase }
        else
            return { top: 0, left: 0, ...savedPositionBase }
    }
})

const originalPush = router.push;
router.push = function( ...args ) {
	return originalPush.call( this, ...args )?.catch( error => {
		if ( error ) {
			console.warn( `consumed router exception: ${error}` );
		} else {
			console.warn( `consumed unknown router exception ${new Error().stack.replace( /^[\s\S]+?at/, "at" )}` );
		}
	} );
};

router.beforeEach(async (to, from, next) => {
    scrollPositions[from.fullPath] = { x: window.scrollX, y: window.scrollY }

	await store.dispatch('CurrentUser/updateNetworkStatus')
	
	if(from.name === 'stay' && ['stays', 'zwr'].includes(to.name)) {
		await store.dispatch('CurrentStays/setDashboardConfig', {
			modal_profile_image_opened: false,
			modal_profile_image_data: null,
			modal_raport_opened: false,
			modal_raport_data: null,
			modal_treatment_form_edit_data: null,
			modal_treatment_form_create_data: null,
		})
	}

	if (to.matched.some((record) => record.meta.requiresAuth) && from.name !== 'login') {
		if (!store.state.CurrentUser.instance) {
			/* get last logged user from cache */
			try {
				await store.dispatch('CurrentUser/get_user_data_from_cache')
			} catch (error) {
				console.error(error)
			}
		}

		if (!store.state.CurrentUser.instance)
			await store.dispatch('CurrentUser/requireUserInfo')

		if (
			!store.state.CurrentUser.instance &&
			store.state.CurrentUser.network_status === 'online'
		)
			await store.dispatch('CurrentUser/login_with_token')

		if (
			(!store.state.CurrentUser.instance ||
				!is_hash_user_pass_hash_exists()) &&
			to.name !== 'login' &&
			from.name !== 'login'
		)
			next({ name: 'login', query: { redirect: to.path } })
	}
	
	const is_exist_rxdb_password = get_rxdb_password() && true,
		is_zwr = store.state.CurrentUser?.instance?.role === 'Personel_ZWR'

	if (
		to.name !== 'login' &&
		to.name !== 'database-sync' &&
		from.name !== 'database-sync' &&
		store.state.CurrentUser.instance &&
		store.state.CurrentUser.network_status === 'online' &&
		is_auth_not_expired() &&
		is_exist_rxdb_password
	) {
		const oldestSyncInfo = getOldestSyncInfo(),
			{ shoudSync, shouldReset } = oldestSyncInfo

		const isInitialized =
			store.state.CurrentDatabase.initialization?.isInitialized

		if (!isInitialized) {
			if (shoudSync || shouldReset) {
				const { diffHumanized } = oldestSyncInfo

				if (shouldReset) {
					console.debug(
						`Najstarsza synchronizacja odbyła się ${diffHumanized} temu, zostanie rozpoczete czyszczenie bazy oraz wymuszone przekierowanie do strony synchronizacji.`
					)
					;(await DatabaseService.singleton()).removeDatabase()
				} else if (
					shoudSync && 
					!is_zwr // DISABLED FOR ZWR
				) {
					console.debug(
						`Najstarsza synchronizacja odbyła się ${diffHumanized} temu, zostanie rozpoczeta synchronizacja.`
					)
					;(await DatabaseService.singleton()).syncDatabase() //TODO PT-1808
				}
			}

			if (pendingSyncTimePassed()) {
				console.debug(
					`Ostatnia synchronizacja odbyła się w czasie trwania importu, zostanie rozpoczeta synchronizacja.`
				)
				;(await DatabaseService.singleton()).syncDatabaseV2({forceIndexes: true})
			}
		}
		
		// tray
		const VUE_APP_MODULE_DRUG_APPLICATION_INTERNAL_ENABLED = getAsBool('VUE_APP_MODULE_DRUG_APPLICATION_INTERNAL_ENABLED'),
			trayPendingChanges = checkIfPendingChanges()
		
		if(
			VUE_APP_MODULE_DRUG_APPLICATION_INTERNAL_ENABLED && 
			['Nurse'].includes(
				store.state.CurrentUser.instance?.role
			)
		){
			if(trayPendingChanges){
				;(await DatabaseService.singleton()).syncTrayChange()
				
				setPendingChanges(false)
			} else if( to.name === 'stays' ){
				const oldestSyncInfo = getOldestSyncInfo('drug_application_internals'),
					{ diffInMs } = oldestSyncInfo,
					trayDedicatedResyncPeroidInMs = getAsInt(
						'VUE_APP_TRAY_DEDICATED_SYNC_RESYNC_PEROID_IN_MILLISECONDS'
					),
					shoudSync = diffInMs >= trayDedicatedResyncPeroidInMs
					
				if(shoudSync){
					;(await DatabaseService.singleton()).syncTrayChange()
				}
			}
		}
	}

	if (
		to.matched.some((record) => record.meta.requireAuthenticatedClasses) &&
		store.state.CurrentUser.network_status === 'online'
	) {
		await store.dispatch('CurrentUser/requireAuthenticatedClasses')
	}

	if (to.matched.some((record) => record.meta.requiresRoleZWR)) {
		if (
			['Personel_ZWR', 'Admin'].includes(
				store.state.CurrentUser.instance?.role
			)
		) {
			// next();
		} else {
			next({ name: 'login', query: { redirect: '/' } })
		}
	}

	if (to.matched.some((record) => record.meta.requirePublicClasses)) {
		await store.dispatch('CurrentUser/requirePublicClasses')
	}

	if (
		(
			from.name == 'statistics' && 
			to.name !== 'statistics' &&
			store.state.CurrentStays.dashboard_config.panel_prev_url &&
			to.name !== 'stay'
		) || (
			from.name == 'drug-applications.drug-applications-tray' &&
			store.state.CurrentStays.dashboard_config.panel_prev_url &&
			![
				'drug-applications.drug-applications',
				'drug-applications.drug-applications-compact',
			].includes(to.name)
		)
	) {
		const route =
			store.state.CurrentStays.dashboard_config.panel_prev_url.fullPath

		store.dispatch('CurrentStays/setDashboardConfig', {
			panel_prev_url: null,
		})

		try {
			next({ path: route })
		} catch (e) {
			console.warn(e)
		}
	}

	if (
		store.state.CurrentUser?.instance?.role === 'Personel_ZWR' &&
		to.name === 'zwr' &&
		['stay', 'start'].includes(from.name) &&
		store.state.CurrentUser?.network_status === 'online' &&
		is_auth_not_expired()
	) {
		const oldestSyncInfo = getOldestSyncInfo('zwr_monitor_orders'),
			{ shoudZwrDedicatedSync } = oldestSyncInfo

		if (shoudZwrDedicatedSync) {
			const isInitialized =
				store.state.CurrentDatabase.initialization?.isInitialized

			if (!isInitialized) {
				const { diffHumanized } = oldestSyncInfo

				console.debug(
					`Najstarsza synchronizacja dedykowana zwr odbyła się ${diffHumanized} temu, zostanie rozpoczeta synchronizacja.`
				)
				;(await DatabaseService.singleton()).syncPartialForZwr()
			}
		}
	}
	
	const isNoteMinimalized = store.state?.CurrentStays?.dashboard_config?.modal_new_note_minimalized
	
	if(to.name === 'stays' && isNoteMinimalized){
		try {
			store.dispatch('CurrentStays/setDashboardConfig', {
				modal_new_note_minimalized_warn_opened: true,
			})
			
			next({...from})
		} catch (e) {
			console.warn(e)
		}
	}
	
	const {modal_new_note_opened, modal_new_note_minimalized} = store.state?.CurrentStays?.dashboard_config,
		isMaximalized = modal_new_note_opened && !modal_new_note_minimalized
	
	if(isMaximalized){
		console.warn('Note is maximalized, redirection not allowed')
		
		return
	}
	
	// signaturex
	if(
		// it can sync
		to.name !== 'login' &&
		to.name !== 'database-sync' &&
		from.name !== 'database-sync' &&
		store.state.CurrentUser.instance &&
		store.state.CurrentUser.network_status === 'online' &&
		is_auth_not_expired() &&
		is_exist_rxdb_password && 
		
		// it should sync
		to.query?.sign_status === 'success' && to.query?.sign_id
	) {
		console.debug('Podpisano poprawnie dokument', to.query?.sign_id)
		
		;(await DatabaseService.singleton()).syncAfterSign()
	}

	next()
})

router.afterEach((to /* from */) => {
	Vue.nextTick(() => {
		if (to.meta.title) document.title = to.meta.title
	})
})

export default router
