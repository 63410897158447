export const couch_uuid = {
		type: 'string',
		final: true,
		maxLength: 1000,
	},
	int_field = {
		type: 'integer',
		multipleOf: '1',
	},
	double_field = {
		type: 'integer',
		multipleOf: '0.01',
	},
	float_field = {
		type: 'integer',
		multipleOf: '0.0001',
	},
	id = {
		...int_field,
		minimum: 0,
		maximum: 999999999999999999,
		// final: true,
	},
	index = {
		maxLength: 20,
	}
