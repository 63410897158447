export const deleteItemFromArray = function (item, array) {
		const index = array.indexOf(item)
		if (index > -1) array.splice(index, 1)

		return array
	},
	deleteItemsFromArray = function (items, array) {
		let arrayNew = array
		items.forEach((item) => {
			arrayNew = deleteItemFromArray(item, arrayNew)
		})

		return arrayNew
	}

export default deleteItemsFromArray
