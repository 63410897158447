import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'

import {promiseAllSynchronous} from '@/functions/promise.js'
import {sendPendingProcess} from '@/functions/events.js'
import {getAsBool, getAsNumber} from '@/functions/env.js'
import { isQuotaExceededError, quotaExceededErrorResolution } from '@/functions/error'
import { keys } from '@/functions/localStorage'
import { limitObjects } from '@/functions/filters'

const VUE_APP_MODULE_SEND_PROCESSES_ENABLED = getAsBool('VUE_APP_MODULE_SEND_PROCESSES_ENABLED')

const VUE_APP_GUI_LOGS_PERIOD_MONTHS_RETENTION = getAsNumber('VUE_APP_GUI_LOGS_PERIOD_MONTHS_RETENTION')

@Module({ namespaced: true })
class CurrentLog extends VuexModule {
	events = []
	key = keys.logs
	
	@Mutation
	restoreFromCache() {
		let data = localStorage.getItem(this.key)

		if (data) {
			data = JSON.parse(data)

			this.events = data || []
		}
	}

	@Mutation
	saveToCache() {
		try {
			localStorage.setItem(this.key, JSON.stringify(this.events))
		} catch (e) {
			if (isQuotaExceededError(e)) quotaExceededErrorResolution(e)
			else throw e
		}
	}
	
	@Mutation
	clearOldData() {
		const events = limitObjects(
			[...this.events],
			'datetimeCreated',
			VUE_APP_GUI_LOGS_PERIOD_MONTHS_RETENTION,
			'months',
			true
		)
		
		this.events = events
	}

	@Mutation
	resetState() {
		this.events = []
	}
	
	@Mutation
	appendEventToState(event) {
		this.events = [...this.events, event]
	}
	
	@Action({ rawError: true })
	async save() {
		this.context.commit('clearOldData')
		this.context.commit('saveToCache')
	}

	@Action({ rawError: true })
	async restore() {
		this.context.commit('restoreFromCache')
	}

	@Action({ rawError: true })
	async reset() {
		this.context.commit('resetState')
		this.context.commit('saveToCache')
	}
	
	@Action({ rawError: true })
	async appendEvent(event) {
		this.context.commit('appendEventToState', event)
		this.context.commit('saveToCache')
		
		if(VUE_APP_MODULE_SEND_PROCESSES_ENABLED)
			this.context.dispatch('sendPendingLogs')
	}
	
	@Action({ rawError: true })
	async getPendingLogs() {
		const pending = this.events.filter(e => !e.id && e.user_id)
		
		return pending
	}
	
	@Action({ rawError: true })
	async sendPendingLogs() {
		const pending = await this.context.dispatch('getPendingLogs')
		
		if(pending.length === 0)
			return
			
		console.debug(`[sendPendingLogs] pending:`, pending)
			
		await promiseAllSynchronous(pending, sendPendingProcess)
	}
	
	@Mutation
	markPendingProcessAsSendUpdate(eventIndex, event = {}) {
		this.events[eventIndex].id = event.id || 'skipped'
	}
	
	@Action({ rawError: true })
	async markPendingProcessAsSend(event = {}) {
		const keys = Object.keys(event).filter(k => k !== 'id')
		
		const eventIndex = this.events.findIndex((e) => {
			// compare all properties except id
			return keys.every((key) => e[key] === event[key])
		})
		
		if(eventIndex === -1)
			throw new Error(`[markPendingProcessAsSend] event not found`)
			
		this.context.dispatch('save')
	}
}

export default CurrentLog
