import syncConfig from './syncConfig'

export const validateSchema = function (collectionName = null, schema = {}) {
	if (!collectionName) throw new Error('No collectionName')
	if (!schema) throw new Error('No schema')
	
	// test 1
	const collectionSyncConf = syncConfig?.[collectionName],
	directionPush = collectionSyncConf?.direction?.push
	
	if(directionPush && schema.indexes)
		throw new Error(`Indexes for bidirectional synced collections are illegal. Collection: ${collectionName}.`)
		
	// test 2
	// ...
}
