export const detectOS = function () {
		const userAgent = navigator.userAgent
		
		// console.debug(`[detectOS]`, userAgent)
		// console.debug(`[detectOS]`, navigator.platform)

		if (/android|Android/i.test(userAgent)) 
			return 'Android'

		if (
			/iPad|iPhone|iPod/.test(userAgent) || 
			(
				// iPad
				/Mac/i.test(userAgent) && 
				"ontouchend" in document && 
				navigator.maxTouchPoints && 
				navigator.maxTouchPoints > 1
			)
		) 
			return 'iOS'

		return 'Desktop'
	},
	isAndroidCheck = function () {
		return detectOS() === 'Android'
	},
	isIPadCheck = function () {
		return detectOS() === 'iOS'
	},
	isMobileCheck = function () {
	    // console.debug(`[isMobileCheck] `, detectOS())
		return isAndroidCheck() || isIPadCheck()
	}

export default detectOS
