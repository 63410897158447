import { couch_uuid } from './fields'

export const schema = {
	title: 'patient attribute alert schema',
	description: 'describes a single patient attribute alert',
	version: 0,
	keyCompression: false,
	primaryKey: 'couch_uuid',
	type: 'object',
	properties: {
		couch_uuid,
		org_unit_uuid: {
			...couch_uuid
		},
		service_uuid: {
			...couch_uuid
		},
		patient_uuid: {
			...couch_uuid
		},

		name: {
			type: 'string',
		},
		duration: {
			type: 'string',
		},
		icon: {
			type: 'string',
		},
		unit: {
			type: 'string',
		},
		shortcut: {
			type: 'string',
		},
	},
	required: ['couch_uuid', 'org_unit_uuid', 'service_uuid', 'patient_uuid'],
}

export default schema
