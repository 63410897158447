import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'

import { isQuotaExceededError, quotaExceededErrorResolution } from '@/functions/error'

@Module({ namespaced: true })
class CurrentSTT extends VuexModule {
	lastRecognizedTime=null
	isRecognizing=false
	key='stt'
	
	recognizing=null
	recognized=null
	action=null
	
	@Mutation
	restoreFromSession() {
		let data = sessionStorage.getItem(this.key)

		if (data) {
			data = JSON.parse(data)

			this.isRecognizing = data.isRecognizing || false
			this.lastRecognizedTime = data.lastRecognizedTime || false
		}
	}

	@Mutation
	saveToSession() {
	    const {lastRecognizedTime, isRecognizing} = this
	    const data = {lastRecognizedTime, isRecognizing}
		
		try {
			sessionStorage.setItem(this.key, JSON.stringify(data))
		} catch (e) {
			if (isQuotaExceededError(e)) quotaExceededErrorResolution(e)
			else throw e
		}
	}

	@Mutation
	resetState() {
		this.lastRecognizedTime = null
        this.isRecognizing=false
		this.recognizing=null
		this.recognized=null
		this.action=null
	}
	
	@Mutation
	setRunState(s) {
		this.isRecognizing=s
	}
	
	@Mutation
	setRecognizing(s) {
		this.recognizing = s
	}
	
	@Mutation
	setAction(s) {
		this.action = s
	}
	
	@Mutation
	setRecognized(s) {
		this.recognized = s
	}
	
	@Mutation
	setLastRecognizedTime(s) {
		this.lastRecognizedTime = s
	}
	
	@Action({ rawError: true })
	async save() {
		this.context.commit('saveToSession')
	}

	@Action({ rawError: true })
	async restore() {
		this.context.commit('restoreFromSession')
	}

	@Action({ rawError: true })
	async reset() {
		this.context.commit('resetState')
		this.context.commit('saveToSession')
	}
	
	@Action({ rawError: true })
	async start() {
		this.context.commit('setRunState', true)
		this.context.commit('saveToSession')
	}
	
	@Action({ rawError: true })
	async stop() {
		this.context.commit('setRunState', false)
		this.context.commit('saveToSession')
	}
	
	@Action({ rawError: true })
	async updateRecognizingResult(str='') {
		this.context.commit('setRecognizing', str)
		this.context.commit('setAction', null)
	}
	
	@Action({ rawError: true })
	async updateRecognizedResult(str='') {
		this.context.commit('setRecognizing', null)
		this.context.commit('setRecognized', str)
	}
	
	@Action({ rawError: true })
	async updateLastRecognizedTime(t=Date.now()) {
		this.context.commit('setLastRecognizedTime', t)
	}
	
	@Action({ rawError: true })
	async updateAction(a=null) {
		this.context.commit('setAction', a)
	}
}

export default CurrentSTT
