import { couch_uuid } from './fields'

export const schema = {
	title: 'stay note role schema',
	description: 'describes a single patient image',
	version: 0,
	keyCompression: false,
	primaryKey: 'couch_uuid',
	type: 'object',
	properties: {
		couch_uuid,
		stay_note_uuid: {
			type: 'string',
			ref: 'stay_notes',
		},
		role: {
			type: 'string',
		},
	},
	required: ['couch_uuid'],
}

export default schema
