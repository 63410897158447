import { couch_uuid, id } from './fields'

export const schema = {
	title: 'diagnose schema',
	description: 'describes a single diagnose',
	version: 0,
	keyCompression: false,
	primaryKey: 'couch_uuid',
	type: 'object',
	properties: {
		couch_uuid,
		id: {
			...id,
		},

		// persistent id
		stay_id_ref: {
			...id,
		},

		icd10_code: {
			type: 'string',
		},
		icd10_name: {
			type: 'string',
		},
		diagnosis_kind_name: {
			type: 'string',
		},

		// not used
		// comment: {
		// 	type: 'string',
		// },
	},
	required: ['couch_uuid', 'id', 'stay_id_ref'],
}

export default schema
