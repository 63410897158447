const stay_id_ref = 0,
	patient_uuid = 0,
	hospitalisation_id_ref = 0
	

import {getAsBool, getAsString} from '@/functions/env'

const VUE_APP_E_T_PER_HOSPITALIZATION_ENABLED = getAsBool('VUE_APP_E_T_PER_HOSPITALIZATION_ENABLED')

const VUE_APP_DRUG_APPLICATIONS_DATE_INDEX_FIELD_NAME = getAsString('VUE_APP_DRUG_APPLICATIONS_DATE_INDEX_FIELD_NAME')


const getQueries = function(){
	let baseQueries = {
		stays: [
			{
				selector: {
					org_unit_code: { $eq: '' },
				},
				sort: [{ org_unit_code: 'asc', couch_uuid: 'asc' }],
			},
		],
		drug_applications: [
			{
				selector: {
					stay_id_ref,
				},
				sort: [{ stay_id_ref: 'desc' }, { [VUE_APP_DRUG_APPLICATIONS_DATE_INDEX_FIELD_NAME]: 'desc' }],
			},
		],
		zwr_monitor_orders: [
			{
				selector: {
					stay_id_ref,
				},
				sort: [],
			},
		],
		zwr_patient_alerts: [
			{
				selector: {
					patient_uuid,
				},
				sort: [{ patient_uuid: 'asc' }],
			},
		],
		patient_profile_images: [
			{
				selector: {
					patient_uuid,
				},
				sort: [],
			},
		],
		org_unit_persons: [
			{
				selector: {},
				sort: [{ org_unit_uuid: 'asc' }, { person_id_ref: 'asc' }],
			},
		],
	}
	
	if(VUE_APP_E_T_PER_HOSPITALIZATION_ENABLED){
		baseQueries = {
			...baseQueries,
			examinations: [
				{
					selector: {
						stay_id_ref,
					},
					sort: [{ stay_id_ref: 'desc' }, { examination_date: 'desc' }],
				},
				{
					selector: {
						hospitalisation_id_ref,
					},
					sort: [{ hospitalisation_id_ref: 'desc' }, { examination_date: 'desc' }],
				},
				{
					selector: {
						hospitalisation_id_ref,
					},
					sort: [{ hospitalisation_id_ref: 'desc' }, { order_date: 'desc' }],
				},
			],
			treatments: [
				{
					selector: {
						hospitalisation_id_ref,
					},
					sort: [{ hospitalisation_id_ref: 'desc' }, { creation_date: 'desc' }],
				},
			],
		}
	} else {
		baseQueries = {
			...baseQueries,
			examinations: [
				{
					selector: {
						stay_id_ref,
					},
					sort: [{ stay_id_ref: 'desc' }, { examination_date: 'desc' }],
				},
				{
					selector: {
						stay_id_ref,
					},
					sort: [{ stay_id_ref: 'desc' }, { order_date: 'desc' }],
				},
			],
			treatments: [
				{
					selector: {
						stay_id_ref,
					},
					sort: [{ stay_id_ref: 'desc' }, { creation_date: 'desc' }],
				},
			],
		}
	}
	
	return baseQueries
}

export const queries = getQueries(),
	getQueriesForCollection = function (collectionName = null) {
		return queries?.[collectionName] || []
	},
	getLengthOfQueries = function (obj = {}) {
		return Object.keys(obj).reduce((acc, key) => acc + obj[key].length, 0)
	}
