import { isQuotaExceededError, quotaExceededErrorResolution } from '@/functions/error'

export const key = 'rxdb-validation-fail-counter',
	validationCounterLimit = parseInt(process.env.VUE_APP_VALIDATION_RETRY_TIMES_LIMIT),
	setValidationCounter = function (value = 0) {
		console.debug(`[setValidationCounter] saving...`, value)
		
		try {
			sessionStorage.setItem(key, value)
		} catch (e) {
			if (isQuotaExceededError(e)) quotaExceededErrorResolution(e)
			else throw e
		}
	},
	getValidationCounter = function () {
		console.debug(`[getValidationCounter]`)
		if (sessionStorage.getItem(key))
			return parseInt(sessionStorage.getItem(key))

		return 0
	},
	validationCounterIncrementLimitExceed = function (
		value = getValidationCounter()
	) {
		console.debug(`[validationCounterIncrementLimitExceed]`, value, validationCounterLimit)
		if (value > validationCounterLimit)
			throw new Error(
				`[validationCounterIncrement] limit exceeded (${validationCounterLimit})`
			)
	},
	validationCounterIncrement = function () {
		console.debug(`[validationCounterIncrement]`)
		const v = getValidationCounter() + 1

		validationCounterIncrementLimitExceed(v)

		setValidationCounter(v)
	},
	validationCounterReset = function () {
		console.debug(`[validationCounterReset]`)
		setValidationCounter()
	},
	validationGetAction = function () {
		const v = getValidationCounter()
		if(v === 1)
			return 'sync-partial'
		else
			return 'reset-full-sync'
	}
