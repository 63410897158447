import {
	isQuotaExceededError,
	quotaExceededErrorResolution,
} from '@/functions/error'

import { keys } from '@/functions/localStorage'

const { repoCommitHashKey } = keys

export const reloadWindow = function () {
		// https://stackoverflow.com/questions/1011605/clear-the-cache-in-javascript
		console.debug(`[reloadWindow] reloading window...`)

		window.location.reload(true)
	},
	shouldClearCache = function () {
		const hash = process.env.VUE_APP_COMMIT_HASH,
			savedHash = localStorage.getItem(repoCommitHashKey)

		try {
			localStorage.setItem(repoCommitHashKey, hash)
		} catch (e) {
			if (isQuotaExceededError(e)) quotaExceededErrorResolution(e)
			else throw e
		}

		if (savedHash && savedHash !== hash) reloadWindow()
	}
