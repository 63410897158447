import moment from 'moment'

import { sortArrBy } from '@/functions/sort'
import { checkIsChartAvaliable, getGroupParamCode, getAltNorm } from '@/functions/paramsOffline'

import store from '@/store'

export const getLatestParamV3 = function (paramCode, arr) {
		const sortByConfig = {
			code: 'examination_date',
			type: 'date',
			direction: 'desc',
		}

		let a =
				arr.length > 0
					? sortArrBy(
							[...arr].filter((e) =>
								e && e?.code == paramCode ? true : false
							),
							sortByConfig
					  )
					: [],
			a1 = [],
			wrongResults = 0,
			diff = null

		a1 = [...arr].filter((e) =>
			e?.code == paramCode && !!e?.value ? true : false
		)

		if (a1.length != a.length) wrongResults = a.length - a1.length

		if (a.length > 0 && !a[0].value) if (a1.length > 0) a = a1

		// diff
		if (a1.length > 2 && !!a1[1].value && a1[0].type === 'NUMBER') {
			if (getItemValue(a1[0], true) > getItemValue(a1[1], true))
				diff = 'up'
			else if (getItemValue(a1[0], true) < getItemValue(a1[1], true))
				diff = 'down'
		}
		
		const value_prop = a?.custom || 'value',
			isChartAvaliable = checkIsChartAvaliable(a1, value_prop)

		return a.length > 0
			? {
					...a[0],
					resultsAmount: a.length - wrongResults,
					diff: diff,
					isChartAvaliable
			  }
			: null
	},
	orderZwr = [
        'Liczba Oddechów',
		'SpO2',
		'Powietrze czy Tlen',
		'Aparat Tlenowy',
        'BP',
        'Puls',
        'Stan świadomości',
        'Temperatura',
        'NEWS2',
        'Ból',
        'Diureza',
        'Dobowy Bilans Płynów',
	],
	order = [
		'Liczba Oddechów',
		'SpO2',
		'SPO2',
		'Saturacja_z',
		'Powietrze czy Tlen',
		'BP',
		'Puls',
		'Stan świadomości',
		'Temperatura',
		'Metoda_pomiaru_temp',
		'Wynik Stan świad. Skala1',
		'Wynik Stan świad. Skala2',
		'Ból',
		'Kontakt słowny',
		'Otwieranie oczu',
		'Reakcja ruchowa',
		'Wynik SpO2 Skala 1',
		'Wynik SpO2 Skala 2',
		'Wynik Puls Skala 1',
		'Wynik Puls Skala 2',
		'Rozkurczowe Ciśnienie',
		'Wynik Skurczowe Ciśnieni',
		'Skurczowe Ciśnienie',
		'Cisn_skurcz',
		'Wynik skurcz cis Skala1',
		'Wynik skurcz cis Skala2',
		'Średnie ciśnienie tętnic',
		'Krążenie',
		'średnie ciśnienie',
		'Osrodkowe_cis_zylne',
		'Rytm_serca',
		'Terapia tlenowa',
		'Aparat Tlenowy',
		'Oddychanie',
		'Wskaźnik masy ciała',
		'Punkt oceny',
		'BMI',
		'PH_mocz',
		'Utrata inne opis',
		'Utrata inne',
		'SUM_utraty',
		'Krwawienie',
		'Wymioty',
		'Waga',
		'pom_wag',
		'Wysokość',
		'Wzrost',
		'pom_wzr',
		'NEWS2',
		'Mobilność pacjenta',
		'Hosp. w obszarze endemic',
		'Przeb.Nieb.Inf.Bakt.Szp',
		'Podejrzenie SARS COV-2',
		'Kontakt z SARS COV-2',
		'Mechanizm urazu',
		'Rodzaj urazu',
		'Sytuacje specjalne',
		'Sugerowany kolor',
		'Podaż_iv',
		'Sonda zgłęb nos.-żoł. Pp',
		'Podaż inne opis',
		'Podaż inne',
		'Diureza',
		'Sonda zgłęb nos.-żoł. Up',
		'Stomia',
		'Dren 1 opis',
		'Dren 1',
		'Dren 2 opis',
		'Dren 2',
		'Dren 3 opis',
		'Dren 3',
		'Dren_1_o',
		'Dren_2_o',
		'Dren_3_o',
		'Dren_2',
		'Dren_4_o',
		'Dren_1',
		'stolec_ml',
		'rodzaj_st',
		'Stolec',
		'STOLEC',
		'Dren_3',
		'Dren_4',
		'ilosc_st',
	]

export const orderParamsByCode = function (arr=[], orderArr=order) {
		const isZwr = store.state.CurrentUser?.instance?.role === 'Personel_ZWR'
		orderArr = isZwr ? orderZwr : order
	
		let itemsSorted = arr.sort((a, b) => {
			let indexOfA = orderArr.indexOf(a.code),
				indexOfB = orderArr.indexOf(b.code)
			
			if(indexOfA === -1){
				const altIndex = orderArr.indexOf(getGroupParamCode(a.code))
				indexOfA = altIndex !== -1 ? altIndex : orderArr.length
			} 
			
			if(indexOfB === -1){
				const altIndex = orderArr.indexOf(getGroupParamCode(b.code))
				indexOfB = altIndex !== -1 ? altIndex : orderArr.length
			}
			
			if (indexOfA < indexOfB) return -1
			if (indexOfA > indexOfB) return 1

			return 0
		})
		
		return itemsSorted
	},
	orderParams = function (arr, orderArr=order) {
		const isZwr = store.state.CurrentUser?.instance?.role === 'Personel_ZWR'
		orderArr = isZwr ? orderZwr : order
			
		let itemsSorted = arr.sort((a, b) => {
			if (a.examination_date != b.examination_date)
				return new Date(a.examination_date) -
					new Date(b.examination_date) >
					0
					? -1
					: 1

			let indexOfA = orderArr.indexOf(a.code),
				indexOfB = orderArr.indexOf(b.code)
			
			if(indexOfA === -1){
				const altIndex = orderArr.indexOf(getGroupParamCode(a.code))
				indexOfA = altIndex !== -1 ? altIndex : orderArr.length
			} 
			
			if(indexOfB === -1){
				const altIndex = orderArr.indexOf(getGroupParamCode(b.code))
				indexOfB = altIndex !== -1 ? altIndex : orderArr.length
			}
			
			if (indexOfA < indexOfB) return -1
			if (indexOfA > indexOfB) return 1

			return 0
		})

		return itemsSorted
	},
	getLatestParamsV3 = function (data) {
		if (data.length > 0) {
			const results = data
				.reduce(function (r, a) {
					if (!r.includes(a?.code)) r.push(a?.code)

					return r
				}, [])
				.map((e) => getLatestParamV3(e, data))
				.filter((e) => !!e?.value)

			return orderParams(results)
		} else return {}
	},
	getItemValue = function (itemData, retNull) {
		if (itemData === null || itemData.value === null)
			return retNull ? null : '-'
		
		// if (itemData.value === '-1' || itemData.text)
		// 	return itemData.text

		if (typeof itemData.value === 'object') {
			if (itemData.value.name === null) {
				return retNull ? null : '-'
			}
			return itemData.value.name
		}

		return itemData.value
	},
	// isChartAvaliable = function (itemData) { //TODO delete this
	// 	return itemData.type === 'NUMBER' && 
	// 		// itemData.resultsAmount > 1 && 
	// 		itemData.isChartAvaliable
	// 		? 'fas fa-chart-pie'
	// 		: false
	// },
	config_alerts = [
		{
			param: 'news',
			params: ['NEWS2', 'Wynik NEWS2 Skala 1', 'Wynik NEWS2 Skala 2'],
			formatting: [
				// Skala news - jeżeli wyższa niż 4, kolory: 5-6 na pomarańczowo, 7< czerwono
				{
					min: 0,
					max: 5, // 4 -> 5 PT-203
					className: 'default',
					isNorm: true,
				},
				{
					min: 5, // 4 -> 5 PT-203
					max: 7,
					className: 'warning',
				},
				{
					min: 7,
					max: 999,
					className: 'alert',
				},
			],
		},
		{
			param: 'temperatura',
			params: [
				'Temperatura',
				'Wynik Temp Skala1',
				'Wynik Temp Skala 2',
				'Wynik Temperatura Skala 1',
				'Wynik Temperatura Skala',
				'TEMP',
			],
			formatting: [
				// temperature, jeżeli wyższa niż 37
				{
					min: 0,
					max: 38, // 37 -> 38 PT-203
					className: 'default',
					isNorm: true,
				},
				{
					min: 38, // 37 -> 38 PT-203
					max: 39, // 38 -> 39 PT-203
					className: 'warning',
				},
				{
					min: 39, // 38 -> 39 PT-203
					max: 999,
					className: 'alert',
				},
			],
		},
		{
			/*
            Skala qSOFA - jeżeli wyższa niż 2, kolory: 2 na pomarańczowo, 3 czerwono
            3 punktowa skala w której pacjent może otrzymać  0/3, 1/3, 2/3 lub 3/3. (2/3 i 3/3 na pomarańczowo)
        */
			param: 'qsofa',
			params: ['qSOFA'],
			formatting: [
				{
					min: 0,
					max: 2,
					className: 'default',
					isNorm: true,
				},
				{
					min: 2,
					max: 3,
					className: 'warning',
				},
				{
					min: 3,
					max: 999,
					className: 'alert',
				},
			],
		},
	],
	getLastParamValue = function (data, param, sortBy) {
		sortBy ||= 'examination_date'

		let diff = null,
			ret = {
				value: null,
				diff: null,
				format: { classname: null },
				isNorm: true,
			}

		let i = config_alerts.findIndex((e) =>
			e.param == param ? true : false
		)

		if (i >= 0) {
			const a =
				data.length > 0
					? data
							.filter((e) =>
								e?.value &&
								config_alerts[i].params.includes(e?.code)
									? true
									: false
							)
							.sort((a, b) => a[sortBy] - b[sortBy])
					: []

			// diff
			if (a.length > 1 && a[0].type === 'NUMBER') {
				if (getItemValue(a[0], true) > getItemValue(a[1], true))
					diff = 'up'
				else if (getItemValue(a[0], true) < getItemValue(a[1], true))
					diff = 'down'
			}

			// format
			if (a.length > 0) {
				let v = getItemValue(a[0]),
					b = config_alerts[i].formatting.filter(
						(e) => e.min <= v && e.max > v
					)[0]

				ret = {
					...a[0],
					value: v,
					diff: diff,
					format: b,
					isNorm: b?.isNorm ? true : false,
					isStaticticsEnabled: a.length > 1 && a[0].type === 'NUMBER',
				}
			}
		}

		return ret
	},
	getParamFormat = function (param, value) {
		let i = config_alerts.findIndex((e) =>
			e.param == param ? true : false
		)

		if (i >= 0) {
			if (config_alerts[i].formatting) {
				let a = config_alerts[i].formatting.filter(
					(e) => e.min <= value && e.max > value
				)

				if (a.length !== 1) {
					console.debug(
						'getParamFormatClassName ERROR, param: ' +
							param +
							', value: ' +
							value
					)
					console.debug(a)
				} else if (a.length === 1) return a[0]
			} else
				console.debug(
					'getParamFormatClassName ERROR [no config_alerts], param: ' +
						param +
						', value: ' +
						value
				)
		}

		return null
	},
	getParamFormatClassName = function (param, value) {
		return getParamFormat(param, value)?.className
	},
	conditionalFormattingClass = function (param) {
		if (param.conditional_formatting)
			if (param.conditional_formatting['background-color-mod'])
				return param.conditional_formatting['background-color-mod']

		return false
	},
	conditionalFormattingIcon = function (param) {
		if (param.conditional_formatting)
			if (param.conditional_formatting['additional-icon-mod'])
				return param.conditional_formatting['additional-icon-mod']

		return false
	},
	fixPatientData = function (s) {
		let isNumber = Number.isInteger(s),
			isString = typeof s === 'string' || s instanceof String
		return s && (s.length > 0 || isNumber) ? (isString ? s.trim() : s) : '-'
	},
	getFormattedDate = function (date, format) {
		format ||= 'YYYY-MM-DD HH:mm'

		return moment(date).format(format)
	},
	isParamNoNormal = function (param) {
		const { norm, value } = param
		
		let { lowerNorm, upperNorm } = norm || {}
		
		if(!lowerNorm && !upperNorm){
			const altNorm = getAltNorm(param?.code)
			
			lowerNorm = altNorm?.min
			upperNorm = altNorm?.max
		}
		
		if (
			lowerNorm && 
			typeof lowerNorm === 'number' && 
			parseFloat(lowerNorm) > value
		) return 'lowerNorm'
		else if (
			upperNorm && 
			typeof upperNorm === 'number' && 
			parseFloat(upperNorm) < value
		) return 'upperNorm'
		
		return null
	}

export default getLatestParamsV3
