export const promiseAllSynchronous = async function (
	arr = [],
	func = async () => {
		return
	}
) {
	if (arr instanceof Promise){
		console.warn(`[promiseAllSynchro] arr is a promise`)
		arr = await arr
	}

	arr?.reduce(function (p, item) {
		return p.then(function () {
			return func(item)
		})
	}, Promise.resolve())
		.then(function () {
			// all done here
			return
		})
		.catch(function (err) {
			// error here
			console.error(`promiseAllSynchro`, err)
		})
}

export const promiseAllAsynchronous = async function (
	arr = [],
	func = async () => {
		return
	}
) {
	return await Promise.all(arr.map(a => func(a)));
}
