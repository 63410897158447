import { couch_uuid } from './fields'

export const schema = {
	title: 'patient attribute alert level schema',
	description: 'describes a single patient attribute alert level',
	version: 0,
	keyCompression: false,
	primaryKey: 'couch_uuid',
	type: 'object',
	properties: {
		couch_uuid,
		alert_attribute_uuid: {
			...couch_uuid,
			ref: 'alert_attributes',
		},

		value_range_type: {
			type: 'string',
		},
		value_range_from: {
			type: 'string',
		},
		value_range_to: {
			type: 'string',
		},
		color: {
			type: 'string',
		},
	},
	required: ['couch_uuid', 'alert_attribute_uuid'],
}

export default schema
