<template>
	<v-app>
		<RefreshPage />
		<div id="app" data-app>
			<AppLayout>
				<router-view />
				<div v-if="showVersion" class="app-version-preview">
					{{ currentAppVersion ? `Wersja aplikacji: ${currentAppVersion}` : 'Brak lokalnej wersji aplikacji' }}
				</div>
			</AppLayout>
		</div>
	</v-app>
</template>

<script>
	import AppLayout from '@/layouts/AppLayout'
	import RefreshPage from '@/components/RefreshPage.vue'
	import { sendPendingReports } from '@/functions/reportProblem'

	export default {
		name: 'App',
		components: { AppLayout, RefreshPage },
		async mounted() {
			await this.$store.dispatch('CurrentLog/restore')
			await this.$store.dispatch('CurrentUser/updateNetworkStatus')
			await sendPendingReports()
		},
		computed: {
			currentAppVersion() {
				return this.$store.getters.appVersion
			},
			showVersion() {
				const routes = ['database-sync', 'login', 'recover-password']
				
				return this.$route.path == '/' || routes.includes(this.$route.name)
			}
		}
	}
</script>

<style lang="scss">
	* {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	a {
		cursor: pointer;
	}

	@import '@/assets/scss/fontawesome';

	@import '@/assets/scss/fonts';

	@import '@/assets/scss/bootstrap';

	@import '@/assets/scss/korban_general';

	.v-application {
		// display: unset !important;
	}
	.notificationCenter {
		z-index: 15000 !important;
	}
	.app-version-preview {
		position: fixed;
		left: 0;
		bottom: 0;
		padding: 8px 20px;
		z-index: 10;
		border-radius: 4px;
		backdrop-filter: blur(20px);
	}
</style>
