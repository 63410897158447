import { couch_uuid, id } from './fields'

export const schema = {
	title: 'zwr monitor orders',
	description: 'describes a zwr monitor orders',
	version: 0,
	keyCompression: false,
	primaryKey: 'couch_uuid',
	type: 'object',
	properties: {
		couch_uuid,
		state: {
			type: 'string',
		},
		start_date: {
			type: 'string',
		},
		stay_id_ref: {
			...id,
		},
		person_id_ref: {
			...id,
		},
		person_first_name: {
			type: 'string',
		},
		person_last_name: {
			type: 'string',
		},
		person_role: {
			type: 'string',
		},
	},
	required: ['couch_uuid', 'stay_id_ref'],
}

export default schema
