import { reloadWindow } from '@/functions/cache.js'

import { clearCachedData } from '@/functions/localStorage'

export const resolveError = function () {
	const error = arguments[0],
		{ message, name } = error

	if (typeof name !== 'undefined') {
		let resolveByReload = false

		// if (name === 'ChunkLoadError') // PT-1167
		// 	resolveByReload = true
		if (
			message ==
			`Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing.`
		)
			// PT-1471
			resolveByReload = true
		else if (message == `Database has a global failure`)
			// PT-1243
			resolveByReload = true

		if (resolveByReload /* || ... */) {
			console.debug(
				`[resolveError] znaleziono rozwiązanie dla erroru '${name}' z wiadomością '${message}' - ${
					resolveByReload ? 'przeładowanie strony' : '???'
				}`
			) //TODO
		}

		if (resolveByReload) {
			reloadWindow()
		}
	}
}

export const getErrorMessage = function (e) {
	return (
		((e.response || {}).data || {}).message ||
		((e.response || {}).data || {}).error_description ||
		e.message ||
		e
	)
}

export const isQuotaExceededError = function(err) {
	return (
		err instanceof DOMException &&
		// everything except Firefox
		(err.code === 22 ||
			// Firefox
			err.code === 1014 ||
			// test name field too, because code might not be present
			// everything except Firefox
			err.name === 'QuotaExceededError' ||
			// Firefox
			err.name === 'NS_ERROR_DOM_QUOTA_REACHED')
	)
}

export const quotaExceededErrorResolution = function(e) {
	console.warn(e)
	
	// clear paricular keys from local storage
	clearCachedData()
}

export const sanitizeError = function (error={}) {
	const regex = /"password":"([^"]*)"/g
	
	if(error?.config?.data && regex.test(error?.config?.data)){
		error.config.data = error.config.data.replace(regex, '"password":"***"')
	}
	
	return error
}